import { Component, DoCheck, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CipFooterService } from 'src/app/services/cfm-footer.service';
import { UsersService } from 'src/app/services/users.service';
import { ModalUpdateFooterDataService } from './modal-update-footer-data.service';
import { URL_IMG } from 'src/app/config/config';
import { MenuService } from 'src/app/services/menu.service';

@Component({
  selector: 'app-modal-update-footer-data',
  templateUrl: './modal-update-footer-data.component.html',
  styles: [
  ]
})
export class ModalUpdateFooterDataComponent implements DoCheck {
  BASE_URL_IMG = URL_IMG;
  n_vez = 0
  stateChooseImg = 0
  requestDataBoxTextHtml = 0
  text = ""

  footerItemToAdd_DATA = {
    id: 0,
    idImg: 0,
    header: '',
    urlImg: '',
    descriptionGeneral: '',
    menuId: 0,
    idFooter: 0,
  }

  ITEMS_FOOTER = []
  MENUS = []

  CPI_FOOTER = {
    backgroundColor: "",
    boldHeader: "",
    colorHeader: "",
    colorText: "",
  }

  CPI_FOOTER_ERROR = {
    backgroundColor: "",
    boldHeader: "",
    colorHeader: "",
    colorText: "",
  }

  theme = {
    name: "light",
    background: "bg-light",
    background2: "bg-light",
    text: "text-dark",
    btnType1: "btn-primary",
  }
  constructor(
    public __UsersService: UsersService,
    public __CipFooterService: CipFooterService,
    public __ModalUpdateFooterDataService: ModalUpdateFooterDataService,
    private toastr: ToastrService,
    public __MenuService: MenuService,
  ) { }

  ngDoCheck() {
    if (this.__ModalUpdateFooterDataService.oculto == "" && this.n_vez == 0 && this.__ModalUpdateFooterDataService.idCIP > 0) {
      this.CIPFOOTER_getByIdCip().then(
        () => {
          this.getAllMenus().then(
            () => {
              this.getItemsByFooterId().then(
                () => {

                }
              ).catch(error => console.error("Error en la promesa", error));
            }
          ).catch(error => console.error("Error en la promesa", error));
        }
      ).catch(error => console.error("Error en la promesa", error));
      this.n_vez = 1;
    }
  }

  validarCampo() {
    this.limpiar();
    if (("" + this.CPI_FOOTER.backgroundColor).length > 29) {
      this.limpiar();
      this.CPI_FOOTER_ERROR.backgroundColor = "Este campo no puede tener mas de 29 caracteres";
      return false;
    } else if (("" + this.CPI_FOOTER.colorHeader).length > 29) {
      this.limpiar();
      this.CPI_FOOTER_ERROR.colorHeader = "Este campo no puede tener mas de 29 caracteres";
      return false;
    } else if (("" + this.CPI_FOOTER.colorText).length > 29) {
      this.limpiar();
      this.CPI_FOOTER_ERROR.colorText = "Este campo no puede tener mas de 29 caracteres";
      return false;
    } else {
      this.limpiar();
      return true;
    }
  }

  getAllMenus(): Promise<boolean> {
    return new Promise((resolve, reject) => {

      this.__MenuService.getAllMenus()
        .subscribe((respuesta: any) => {
          if (respuesta.ok) {
            this.MENUS = respuesta.data_array;
            resolve(true);
            return;
          }
          resolve(true);
        });
    })
  }

  getItemsByFooterId(): Promise<boolean> {
    return new Promise((resolve, reject) => {

      this.__CipFooterService.getItemsByFooterId({ idFooter: this.__ModalUpdateFooterDataService.idFooter })
        .subscribe((respuesta: any) => {
          console.log(respuesta)
          if (respuesta.ok) {
            this.ITEMS_FOOTER = respuesta.data_array;
            resolve(true);
            return;
          }
          resolve(true);
        });
    })
  }

  setImg(e: any, state: number) {
    console.log(e)
    if (state === 1) {
      this.footerItemToAdd_DATA.idImg = e.idImg
      this.footerItemToAdd_DATA.urlImg = e.urlImg
      return;
    }
    this.stateChooseImg = 0
    this.footerItemToAdd_DATA.idImg = 0
    this.footerItemToAdd_DATA.urlImg = ''
  }

  limpiar() {
    this.CPI_FOOTER_ERROR = {
      backgroundColor: "",
      boldHeader: "",
      colorHeader: "",
      colorText: "",
    }
  }

  limpiar2() {
    this.n_vez = 0
    this.stateChooseImg = 0
    this.requestDataBoxTextHtml = 0
    this.text = ""

    this.ITEMS_FOOTER = []
    this.MENUS = []

    this.footerItemToAdd_DATA = {
      id: 0,
      idImg: 0,
      header: '',
      urlImg: '',
      descriptionGeneral: '',
      menuId: 0,
      idFooter: 0,
    }
    this.CPI_FOOTER = {
      backgroundColor: "",
      boldHeader: "",
      colorHeader: "",
      colorText: "",
    }
    this.CPI_FOOTER_ERROR = {
      backgroundColor: "",
      boldHeader: "",
      colorHeader: "",
      colorText: "",
    }
  }

  limpiar3() {
    this.stateChooseImg = 0
    this.requestDataBoxTextHtml = 0
    this.text = ""

    this.ITEMS_FOOTER = []

    this.footerItemToAdd_DATA = {
      id: 0,
      idImg: 0,
      header: '',
      urlImg: '',
      descriptionGeneral: '',
      menuId: 0,
      idFooter: 0,
    }
  }

  CIPFOOTER_getByIdCip(): Promise<boolean> {
    return new Promise((resolve, reject) => {

      let dataEnviar = {
        "idCip": this.__ModalUpdateFooterDataService.idCIP,
        "token": this.__UsersService.token
      }

      this.__CipFooterService.getByIdCip(dataEnviar)
        .subscribe((respuesta: any) => {
          if (respuesta.ok) {
            this.CPI_FOOTER.backgroundColor = respuesta.data_array.CIPF_backgroundColor;
            this.CPI_FOOTER.boldHeader = respuesta.data_array.CIPF_boldHeader;
            this.CPI_FOOTER.colorHeader = respuesta.data_array.CIPF_colorHeader;

            this.CPI_FOOTER.colorText = respuesta.data_array.CIPF_colorText;
            resolve(true);
            return;
          }
          resolve(true);
        });
    })
  }

  selectItemFooter(item: any, reset = 0) {
    this.text = '__update';
    if (reset == 0) {
      this.footerItemToAdd_DATA = {
        id: item.fi_id,
        idImg: item.fi_idImage,
        header: item.fi_header,
        urlImg: item.i_routeImg,
        descriptionGeneral: item.fi_text,
        menuId: item.fi_idMenu,
        idFooter: item.fi_idFooter,
      }
      setTimeout(() => {
        this.text = item.fi_text;
      }, 200);
      return
    }
    this.footerItemToAdd_DATA = {
      id: 0,
      idImg: 0,
      header: '',
      urlImg: '',
      descriptionGeneral: '',
      menuId: 0,
      idFooter: 0,
    }
    setTimeout(() => {
      this.text = '';
    }, 200);
  }

  removeItemFooter(id: number) {
    let dataEnviar = {
      "token": this.__UsersService.token,
      "idItemFooter": id,
    }
    this.__CipFooterService.removeItemFooter(dataEnviar)
      .subscribe((resp2: any) => {
        if (resp2.ok) {
          this.toastr.success("Ítem agregado", 'Éxito!', { timeOut: 4000 });

          this.__CipFooterService.__afterUpdateDataFooter();
          this.getItemsByFooterId();
        } else {
          this.toastr.error("Error", 'Advertencia!', { timeOut: 4000 });
        }
      });
  }

  addItemToFooter(refreshData = 0) {
    console.log('addItemToFooter')
    console.log(refreshData)
    if (refreshData == 1) {
      this.requestDataBoxTextHtml = 1;
      return;
    }

    // if (!this.validarCampo()) {
    //   console.log("NOPE")
    //   return;
    // }

    let dataEnviar = {
      "token": this.__UsersService.token,
      "idCIP": this.__ModalUpdateFooterDataService.idCIP,
      "idImagen": this.footerItemToAdd_DATA.idImg,
      "header": this.footerItemToAdd_DATA.header,
      "text": this.footerItemToAdd_DATA.descriptionGeneral,
      "idMenu": this.footerItemToAdd_DATA.menuId,
      "idFooter": this.__ModalUpdateFooterDataService.idFooter,
    }

    console.log(dataEnviar)
    this.selectItemFooter('', 1);
    this.__CipFooterService.addItemToFooter(dataEnviar)
      .subscribe((resp2: any) => {
        if (resp2.ok) {
          this.toastr.success("Ítem agregado", 'Éxito!', { timeOut: 4000 });

          this.__CipFooterService.__afterUpdateDataFooter();
          this.limpiar3();
          this.getItemsByFooterId();
        } else {
          this.toastr.error("Error", 'Advertencia!', { timeOut: 4000 });
        }
      });
  }

  updateItemToFooter(refreshData = 0) {
    console.log('updateItemToFooter')
    console.log(refreshData)
    if (refreshData == 1) {
      this.requestDataBoxTextHtml = 1;
      return;
    }

    // if (!this.validarCampo()) {
    //   console.log("NOPE")
    //   return;
    // }

    let dataEnviar = {
      "token": this.__UsersService.token,
      "idCIP": this.__ModalUpdateFooterDataService.idCIP,
      "id": this.footerItemToAdd_DATA.id,
      "idImagen": this.footerItemToAdd_DATA.idImg,
      "header": this.footerItemToAdd_DATA.header,
      "text": this.footerItemToAdd_DATA.descriptionGeneral,
      "idMenu": this.footerItemToAdd_DATA.menuId,
      "idFooter": this.__ModalUpdateFooterDataService.idFooter,
    }

    console.log(dataEnviar)
    this.selectItemFooter('', 1);
    this.__CipFooterService.updateItemToFooter(dataEnviar)
      .subscribe((resp2: any) => {
        if (resp2.ok) {
          this.toastr.success("Ítem agregado", 'Éxito!', { timeOut: 4000 });

          this.__CipFooterService.__afterUpdateDataFooter();
          this.limpiar3();
          this.getItemsByFooterId();
        } else {
          this.toastr.error("Error", 'Advertencia!', { timeOut: 4000 });
        }
      });
  }

  updateFooterData() {

    if (!this.validarCampo()) {
      console.log("NOPE")
      return;
    }

    let dataEnviar = {
      "token": this.__UsersService.token,
      "idCIP": this.__ModalUpdateFooterDataService.idCIP,
      ...this.CPI_FOOTER
    }
    this.__CipFooterService.updateFooterData(dataEnviar)
      .subscribe((resp2: any) => {
        if (resp2.ok) {
          this.toastr.success("Datos actualizados.", 'Éxito!', { timeOut: 4000 });

          this.__CipFooterService.__afterUpdateDataFooter();
          this.cerrarModal();
        } else {
          this.toastr.error("Error", 'Advertencia!', { timeOut: 4000 });
        }
      });
  }

  getDataBoxTextHtml(e: any) {
    console.log('llll')
    setTimeout(() => {
      this.requestDataBoxTextHtml = 0
      this.footerItemToAdd_DATA.descriptionGeneral = e;
      if (this.footerItemToAdd_DATA.id > 0) {
        this.updateItemToFooter()
      } else {
        this.addItemToFooter()
      }
    }, 500);

  }

  cerrarModal() {
    this.limpiar2()
    this.__CipFooterService.__afterUpdateDataFooter();
    this.__ModalUpdateFooterDataService.ocultarModal();
  }

}
