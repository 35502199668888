import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddComponentComponent } from './modal-component/modal-component.component';
import { FormsModule } from '@angular/forms';
import { ModalImageComponent } from './modal-image/modal-image.component';
import { ModalUpdateFooterDataComponent } from './modal-update-footer-data/modal-update-footer-data.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { ModalItemSlideshowComponent } from './modal-item-slideshow/modal-item-slideshow.component';
import { ComponentsPlusModule } from '../componentsPlus/components-plus.module';
import { ModalImageWithTextOverlayComponent } from './modal-image-with-text-overlay/modal-image-with-text-overlay.component';
import { ModalImageWithTextComponent } from './modal-image-with-text/modal-image-with-text.component';
import { ModalMenuComponent } from './modal-menu/modal-menu.component';
import { ModalMenuItemComponent } from './modal-menu-item/modal-menu-item.component';
import { ModalChooseAndSetMenuComponent } from './modal-choose-and-set-menu/modal-choose-and-set-menu.component';
import { ModalProductComponent } from './modal-product/modal-product.component';
import { ModalEntryComponent } from './modal-entry/modal-entry.component';
import { ModalVariantComponent } from './modal-variant/modal-variant.component';
import { ModalHeaderComponent } from './modal-header/modal-header.component';
import { ModalOurPricesComponent } from './modal-our-prices/modal-our-prices.component';
import { ModalItemOurPricesComponent } from './modal-item-our-prices/modal-item-our-prices.component';
import { ModalDescriptionItemOurPricesComponent } from './modal-description-item-our-prices/modal-description-item-our-prices.component';
import { ModalContactUsComponent } from './modal-contact-us/modal-contact-us.component';
import { ModalSectionTextComponent } from './modal-section-text/modal-section-text.component';
import { ModalPageComponent } from './modal-page/modal-page.component';

@NgModule({
  declarations: [
    AddComponentComponent,
    ModalImageComponent,
    ModalUpdateFooterDataComponent,
    ModalItemSlideshowComponent,
    ModalImageWithTextOverlayComponent,
    ModalImageWithTextComponent,
    ModalMenuComponent,
    ModalMenuItemComponent,
    ModalChooseAndSetMenuComponent,
    ModalProductComponent,
    ModalEntryComponent,
    ModalVariantComponent,
    ModalHeaderComponent,
    ModalOurPricesComponent,
    ModalItemOurPricesComponent,
    ModalDescriptionItemOurPricesComponent,
    ModalContactUsComponent,
    ModalSectionTextComponent,
    ModalPageComponent,
  ],
  imports: [
    FormsModule,
    CommonModule,
    ColorPickerModule,
    ComponentsPlusModule,
  ],
  exports: [
    AddComponentComponent,
    ModalImageComponent,
    ModalUpdateFooterDataComponent,
    ModalItemSlideshowComponent,
    ModalImageWithTextOverlayComponent,
    ModalImageWithTextComponent,
    ModalMenuComponent,
    ModalMenuItemComponent,
    ModalChooseAndSetMenuComponent,
    ModalProductComponent,
    ModalEntryComponent,
    ModalVariantComponent,
    ModalHeaderComponent,
    ModalOurPricesComponent,
    ModalItemOurPricesComponent,
    ModalDescriptionItemOurPricesComponent,
    ModalContactUsComponent,
    ModalSectionTextComponent,
    ModalPageComponent,
  ]
})
export class ModalModule { }
