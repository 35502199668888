import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ModalImageService {

  public oculto = "oculto";
  public toDo = "";
  public id = 0;

  constructor() { }

  ocultarModal() {
    this.oculto = "oculto";
    this.toDo = "";
    this.id = 0;
  }

  mostrarModal(id: number, toDo: string) {
    this.id = id;
    this.toDo = toDo;
    this.oculto = "";
  }
}
