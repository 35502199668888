import { Injectable, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { URL_SERVICIOS } from 'src/app/config/config';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  constructor(
    public http: HttpClient
  ) { }

  @Output() refreshData: EventEmitter<boolean> = new EventEmitter();
  __refreshData() {
    this.refreshData.emit();
  }

  addMenu(data: any) {
    let url = URL_SERVICIOS + "menu_addMenu";

    return this.http.post(url, data)
      .map((resp: any) => {
        return resp;
      })
      .catch(err => {
        console.log("+++++++++++++++++++++++++");
        console.log(err);
        console.log("+++++++++++++++++++++++++");

        return of(err);
      });
  }

  updateMenu(data: any) {
    let url = URL_SERVICIOS + "menu_updateMenu";

    return this.http.post(url, data)
      .map((resp: any) => {
        return resp;
      })
      .catch(err => {
        console.log("+++++++++++++++++++++++++");
        console.log(err);
        console.log("+++++++++++++++++++++++++");

        return of(err);
      });
  }

  addItemMenu(data: any) {
    let url = URL_SERVICIOS + "menu_addItemMenu";

    return this.http.post(url, data)
      .map((resp: any) => {
        return resp;
      })
      .catch(err => {
        console.log("+++++++++++++++++++++++++");
        console.log(err);
        console.log("+++++++++++++++++++++++++");

        return of(err);
      });
  }

  deleteMenu(data: any) {
    let url = URL_SERVICIOS + "menu_deleteMenu";

    return this.http.post(url, data)
      .map((resp: any) => {
        return resp;
      })
      .catch(err => {
        console.log("+++++++++++++++++++++++++");
        console.log(err);
        console.log("+++++++++++++++++++++++++");

        return of(err);
      });
  }

  deleteItemMenu(data: any) {
    let url = URL_SERVICIOS + "menu_deleteItemMenu";

    return this.http.post(url, data)
      .map((resp: any) => {
        return resp;
      })
      .catch(err => {
        console.log("+++++++++++++++++++++++++");
        console.log(err);
        console.log("+++++++++++++++++++++++++");

        return of(err);
      });
  }

  updateItemMenu(data: any) {
    let url = URL_SERVICIOS + "menu_updateItemMenu";

    return this.http.post(url, data)
      .map((resp: any) => {
        return resp;
      })
      .catch(err => {
        console.log("+++++++++++++++++++++++++");
        console.log(err);
        console.log("+++++++++++++++++++++++++");

        return of(err);
      });
  }

  getMenuProcessedForSearch(data: any) {
    let url = URL_SERVICIOS + "menu_getMenuProcessedForSearch&token=" + data.token + "&wordSearched=" + data.wordSearched;

    return this.http.get(url)
      .map((resp: any) => {
        return resp;
      })
      .catch(err => {
        console.log("+++++++++++++++++++++++++");
        console.log(err);
        console.log("+++++++++++++++++++++++++");
        return of(err);
      });
  }

  getAllMenus() {
    let url = URL_SERVICIOS + "menu_getAllMenus";

    return this.http.get(url)
      .map((resp: any) => {
        return resp;
      })
      .catch(err => {
        console.log("+++++++++++++++++++++++++");
        console.log(err);
        console.log("+++++++++++++++++++++++++");
        return of(err);
      });
  }

  getMenuById(data: any) {
    let url = URL_SERVICIOS + "menu_getMenuById&idMenu=" + data.idMenu;

    return this.http.get(url)
      .map((resp: any) => {
        return resp;
      })
      .catch(err => {
        console.log("+++++++++++++++++++++++++");
        console.log(err);
        console.log("+++++++++++++++++++++++++");
        return of(err);
      });
  }

  getItemMenuById(data: any) {
    let url = URL_SERVICIOS + "menu_getItemMenuById&id=" + data.id;

    return this.http.get(url)
      .map((resp: any) => {
        return resp;
      })
      .catch(err => {
        console.log("+++++++++++++++++++++++++");
        console.log(err);
        console.log("+++++++++++++++++++++++++");
        return of(err);
      });
  }

  getAllItemsByIdMenu(data: any) {
    let url = URL_SERVICIOS + "menu_getAllItemsByIdMenu&idMenu=" + data.idMenu;

    return this.http.get(url)
      .map((resp: any) => {
        return resp;
      })
      .catch(err => {
        console.log("+++++++++++++++++++++++++");
        console.log(err);
        console.log("+++++++++++++++++++++++++");
        return of(err);
      });
  }
}
