<div class="w-100">

    <div *ngIf="ItemsToSend.renderFor==='button'" class="form-group row">
        <label class="col-md-3 label-control text-left">
            Color de fondo
        </label>
        <div class="col-md-9">
            <input class="w-100" [(ngModel)]="ItemsToSend.backgroundColor" name="backgroundColor"
                [(colorPicker)]="ItemsToSend.backgroundColor" [style.background]="ItemsToSend.backgroundColor" />
            <p *ngIf="textItemError.backgroundColor" class="text-danger mb-0">
                {{textItemError.backgroundColor}}
            </p>
        </div>
    </div>

    <div class="form-group row">
        <label class="col-md-3 label-control text-left">
            Color
        </label>
        <div class="col-md-9">
            <input class="w-100" [(ngModel)]="ItemsToSend.colorText" name="colorText"
                [(colorPicker)]="ItemsToSend.colorText" [style.background]="ItemsToSend.colorText" />
            <p *ngIf="textItemError.colorText" class="text-danger mb-0">
                {{textItemError.colorText}}
            </p>
        </div>
    </div>

    <div class="form-group row mt-2">
        <label class="col-md-3 label-control text-left">
            Tamaño (px)
        </label>
        <div class="col-md-9">
            <div class="row">
                <div class="col-md-3">
                    {{ItemsToSend.fontSizeText}}px
                </div>

                <div class="col-md-9">
                    <div class="slidecontainer">
                        <input type="range" min="0" max="100" [(ngModel)]="ItemsToSend.fontSizeText" name="fontSizeText"
                            class="slider">
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="form-group row mt-2">
        <label class="col-md-3 label-control text-left">
            Texto negrita (bold)
        </label>
        <div class="col-md-9">
            <select [(ngModel)]="ItemsToSend.boldText" name="boldText"
                class="form-control {{theme.background}} {{theme.text}}">
                <option value="unset">No</option>
                <option value="bold">Si</option>
            </select>
        </div>
    </div>

    <div *ngIf="ItemsToSend.renderFor==='button'" class="form-group row mt-2">
        <label class="col-md-3 label-control text-left">
            Borde?
        </label>
        <div class="col-md-9">
            <select [(ngModel)]="ItemsToSend.border" name="border"
                class="form-control {{theme.background}} {{theme.text}}">
                <option value="0">No</option>
                <option value="1">Si</option>
            </select>
        </div>
    </div>

    <div *ngIf="ItemsToSend.renderFor==='button'" class="form-group row mt-2">
        <label class="col-md-3 label-control text-left">
            Alineación
        </label>
        <div class="col-md-9">
            <select [(ngModel)]="ItemsToSend.align" name="align"
                class="form-control {{theme.background}} {{theme.text}}">
                <option value="left">Izquierda</option>
                <option value="center">Centrado</option>
                <option value="right">Derecha</option>
            </select>
        </div>
    </div>

    <div *ngIf="ItemsToSend.renderFor==='button'" class="form-group row mt-2">
        <label class="col-md-3 col-form-label">Enlace</label>
        <div class="col-md-9">
            <app-search-routes *ngIf="stateToShowSearchRoutes===1" (emitOnChange)="getLink($event)">
            </app-search-routes>
            <!-- <input class="form-control" [(ngModel)]="MENU_ITEM_DATA.url" name="url" />
            <p class="text-danger">{{MENU_ITEM_DATA_ERROR.url}}</p> -->
        </div>
    </div>

    <div *ngIf="ItemsToSend.renderFor==='button'" class="form-group row mt-2">
        <label class="col-md-3 col-form-label">Enlace seleccionado</label>
        <div class="col-md-9">
            <!-- <input class="form-control" [(ngModel)]="MENU_ITEM_DATA.url" name="url" /> -->
            <p class="text-warning">{{urlSelected}}</p>
        </div>
    </div>

    <div class="form-group row mt-2">
        <label class="col-md-3 label-control text-left">Texto</label>
        <div class="col-md-9">
            <textarea [(ngModel)]="ItemsToSend.text" autocomplete="none"
                class="{{theme.background}} {{theme.text}} form-control" name="text" cols="30" rows="4"></textarea>
            <p *ngIf="textItemError.text" class="text-danger mb-0">
                {{textItemError.text}}
            </p>
        </div>
    </div>

    <div class="form-group row mt-2">
        <label class="col-md-3 label-control text-left">Resultado</label>
        <div class="col-md-9 p-2">
            <span *ngIf="ItemsToSend.renderFor==='span'" [style.color]="ItemsToSend.colorText"
                [style.fontSize]="ItemsToSend.fontSizeText+'px'" [style.fontWeight]="ItemsToSend.boldText">
                {{ItemsToSend.text}}
            </span>
            <button *ngIf="ItemsToSend.renderFor==='button'" [style.color]="ItemsToSend.colorText"
                [style.background-color]="ItemsToSend.backgroundColor" [style.fontSize]="ItemsToSend.fontSizeText+'px'"
                [style.fontWeight]="ItemsToSend.boldText"
                [ngStyle]="{'border': (ItemsToSend.border===1) ? '1px solid '+ItemsToSend.colorText : 'unset','float': (ItemsToSend.align==='left' || ItemsToSend.align==='right') ? ItemsToSend.align : 'unset'}">
                {{ItemsToSend.text}}
            </button>
        </div>
    </div>
</div>