import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ModalOurPricesService {

  public oculto = "oculto";
  public idComponentForMange = 0;

  constructor() { }

  ocultarModal() {
    this.oculto = "oculto";
    this.idComponentForMange = 0;
  }
  mostrarModal(idComponentForMange: number) {
    this.oculto = "";
    this.idComponentForMange = idComponentForMange;
  }
}
