import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CarouselComponent } from './carousel/carousel.component';
import { MenuComponent } from './menu/menu.component';
import { ImageWithTextComponent } from './image-with-text/image-with-text.component';
import { ImageWithTextOverlayComponent } from './image-with-text-overlay/image-with-text-overlay.component';
import { FooterComponent } from './footer/footer.component';
import { RouterModule } from '@angular/router';
import { SideBarAdminComponent } from './side-bar-admin/side-bar-admin.component';
import { SectionParticlesComponent } from './section-particles/section-particles.component';
import { NgParticlesModule } from 'ng-particles';
import { OurServicesComponent } from './our-services/our-services.component';
import { OurPricesComponent } from './our-prices/our-prices.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { SectionTextComponent } from './section-text/section-text.component';
import { ComponentsPlusModule } from '../componentsPlus/components-plus.module';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    CarouselComponent,
    MenuComponent,
    ImageWithTextComponent,
    ImageWithTextOverlayComponent,
    FooterComponent,
    SideBarAdminComponent,
    SectionParticlesComponent,
    OurServicesComponent,
    OurPricesComponent,
    ContactUsComponent,
    SectionTextComponent,
  ],
  exports: [
    CarouselComponent,
    MenuComponent,
    FooterComponent,
    SideBarAdminComponent,
    ImageWithTextComponent,
    ImageWithTextOverlayComponent,
    SectionParticlesComponent,
    OurServicesComponent,
    OurPricesComponent,
    ContactUsComponent,
    SectionTextComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    NgParticlesModule,
    ComponentsPlusModule,
    FormsModule,
  ]
})
export class ComponentsModule { }
