import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ModalEntryService {

  public oculto = "oculto";
  public id = 0;

  constructor() { }

  ocultarModal() {
    this.oculto = "oculto";
    this.id = 0;
  }
  mostrarModal(id: number = 0) {
    this.oculto = "";
    this.id = id;
  }
}
