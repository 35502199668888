import { Injectable, Output, EventEmitter } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { URL_SERVICIOS } from 'src/app/config/config';

import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { Router } from '@angular/router';

import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  usuarioData: any;
  ipClient: string = "";
  token: string = "";
  token_exp: string = "";

  constructor(
    public http: HttpClient,
    public router: Router
  ) {
    this.cargarStorage();
  }

  @Output() refreshStateLoginUser: EventEmitter<boolean> = new EventEmitter();
  __refreshStateLoginUser(state: any) {
    this.refreshStateLoginUser.emit(state);
  }

  sendMail(modelPassword: any) {
    let url = URL_SERVICIOS + "sendMail";

    return this.http.post(url, modelPassword)
      .map((resp: any) => {
        return resp;
      })
      .catch(err => {
        console.log("----------------");
        console.log(err);
        console.log("----------------");
        return of(err);
      });
  }

  updatePasswrod(modelPassword: any) {
    let url = URL_SERVICIOS + "updatePasswrod";

    return this.http.post(url, modelPassword)
      .map((resp: any) => {
        return resp;
      })
      .catch(err => {
        console.log("----------------");
        console.log(err);
        console.log("----------------");
        return of(err);
      });
  }

  cargarStorage() {
    if (localStorage.getItem("token")) {
      this.token = "" + localStorage.getItem("token");
      this.token_exp = "" + localStorage.getItem("token_exp");
      this.ipClient = "" + localStorage.getItem("ipClient");
    } else {
      this.ipClient = "";
      this.token = "";
      this.token_exp = "";
      this.usuarioData = null;

      console.log("---TO LOGOUT---")
      // this.logout();
    }
  }

  addUserFromRegister(usuario: any) {
    let url = URL_SERVICIOS + "addUserFromRegister";


    return this.http.post(url, usuario)
      .map((resp: any) => {
        return resp;
      })
      .catch(err => {
        console.log("----------------");
        console.log(err);
        console.log("----------------");

        return of(err);


      });
  }

  buscarUsuario(usuario: any) {
    let url = URL_SERVICIOS + "usuarios_obtenerUsuarioPorEmail&data1=" + usuario.email;

    return this.http.get(url)
      .map((resp: any) => {
        return resp;
      })
      .catch((err: any) => {
        console.log("----------------");
        console.log(err);
        console.log("----------------");
        return of(err);
      });
  }

  estaLogueado() {
    let d_token = this.token;

    if (!d_token) {
      d_token = "0";
    }

    // let url=URL_SERVICIOS+ "login/obtener_dataToken?token="+d_token;
    let url = URL_SERVICIOS + "loginObtenerDataToken&data1=" + d_token;
    return this.http.get(url)
      .map((resp: any) => {
        return resp.ok;
      })
      .catch(err => {
        console.log("----------------");
        console.log(err);
        console.log("----------------");

        return of(err);
      });

  }

  login(usuario: any, recordar: boolean = false, ipClient: string) {
    // if (recordar) {
    //   localStorage.setItem("email", usuario.email);
    // } else {
    //   localStorage.removeItem("email");
    // }

    let url = URL_SERVICIOS + "login";
    // let url= URL_SERVICIOS+ "login/";

    return this.http.post(url, usuario)
      .map((resp: any) => {
        if (!resp.ok) {
          return resp;
        }
        this.guardarStorage(resp.Token, resp.Token_expiracion, resp.usuario, ipClient);
        this.__refreshStateLoginUser(1);
        return resp;
      })
      .catch(err => {
        console.log("----------------");
        console.log(err);
        console.log("----------------");
        return of(err);
      });
  }

  guardarStorage(token: string, token_exp: string, usuario: any, ipClient: string) {
    localStorage.setItem("ipClient", ipClient);
    localStorage.setItem("token", token);
    localStorage.setItem("token_exp", token_exp);

    this.ipClient = ipClient;
    this.token = token;
    this.token_exp = token_exp;
    this.usuarioData = usuario;
  }

  logout() {
    this.ipClient = "";
    this.token = "";
    this.token_exp = "";
    this.usuarioData = null;

    localStorage.removeItem("ipClient");
    localStorage.removeItem("token");
    localStorage.removeItem("token_exp");

    this.__refreshStateLoginUser(0);
    this.router.navigate(["/"]);
  }

  getIP() {
    let url = "https://api.ipify.org?format=json";

    return this.http.get(url)
      .map((resp: any) => {
        return resp;
      })
      .catch(err => {
        console.log("+++++++++++++++++++++++++");
        console.log(err);
        console.log("+++++++++++++++++++++++++");
        return of(err);
      });
  }
}
