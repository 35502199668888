import { Component, DoCheck, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ProductService } from 'src/app/services/product.service';
import { UsersService } from 'src/app/services/users.service';
import { ModalProductService } from './modal-product.service';

@Component({
  selector: 'app-modal-product',
  templateUrl: './modal-product.component.html',
  styles: [
  ]
})
export class ModalProductComponent implements DoCheck {

  requestDataBoxTextHtml = 0
  n_vez = 0
  text = ""

  DATA_TO_ADD = {
    "id": 0,
    "name": "",
    "descriptionGeneral": "",
    "sellAfterOutOfStock": 0,
    "physicalProduct": 0,
    "useWithVariants": 0,
    "stateNormal": 0,
    "state": 0,
    "token": "",
  }

  DATA_TO_ADD_ERROR = {
    "id": "",
    "name": "",
    "descriptionGeneral": "",
    "sellAfterOutOfStock": "",
    "physicalProduct": "",
    "useWithVariants": "",
    "state": "",
  }

  theme = {
    name: "light",
    background: "bg-light",
    background2: "bg-light",
    text: "text-dark",
    btnType1: "btn-primary",
  }

  constructor(
    public __UsersService: UsersService,
    public __ProductService: ProductService,
    private toastr: ToastrService,
    public __ModalProductService: ModalProductService,
  ) { }

  ngDoCheck() {
    if (this.__ModalProductService.oculto == "" && this.n_vez == 0 && this.__ModalProductService.id > 0) {
      this.DATA_TO_ADD.id = this.__ModalProductService.id
      this.cargarDatos();
      this.n_vez = 1;
    }
  }

  cargarDatos() {
    // this._Cargando.mostrarAnimacion();
    this.getProductById().then(
      () => {

      }
    ).catch(error => console.error("Error en la promesa", error));
  }

  getProductById(): Promise<boolean> {
    return new Promise((resolve, reject) => {

      let dataEnviar = {
        "productId": this.DATA_TO_ADD.id,
        "token": this.__UsersService.token,
      }

      this.__ProductService.getProductById(dataEnviar)
        .subscribe((respuesta: any) => {
          console.log("getProductById")
          console.log(respuesta)
          if (respuesta.ok) {
            this.DATA_TO_ADD.name = respuesta.data[0].P_name
            this.DATA_TO_ADD.descriptionGeneral = respuesta.data[0].P_descriptionGeneral
            this.DATA_TO_ADD.state = respuesta.data[0].P_state
            this.DATA_TO_ADD.stateNormal = respuesta.data[0].P_stateNormal
            this.DATA_TO_ADD.sellAfterOutOfStock = respuesta.data[0].P_sellAfterOutOfStock

            this.text = respuesta.data[0].P_descriptionGeneral
            resolve(true);
            return;
          }
          this.DATA_TO_ADD.id = 0
          resolve(true);
        });
    })
  }

  addProduct(refreshData = 0) {
    if (refreshData == 1) {
      this.requestDataBoxTextHtml = 1;
      return;
    }

    this.DATA_TO_ADD.token = this.__UsersService.token;
    this.__ProductService.addProduct(this.DATA_TO_ADD)
      .subscribe((resp2: any) => {
        if (resp2.ok) {
          this.toastr.success("Producto agregado con éxito.", 'Éxito!', { timeOut: 4000 });
          this.__ProductService.__refresh()
          this.cerrarModal()
        } else {
          this.toastr.error(resp2.msn, 'Advertencia!', { timeOut: 4000 });
        }
      });
  }

  updateProduct(refreshData = 0) {
    if (refreshData == 1) {
      this.requestDataBoxTextHtml = 1;
      return;
    }

    this.DATA_TO_ADD.token = this.__UsersService.token;
    this.__ProductService.updateProduct(this.DATA_TO_ADD)
      .subscribe((resp2: any) => {
        if (resp2.ok) {
          this.toastr.success("Producto actualizado con éxito.", 'Éxito!', { timeOut: 4000 });
          this.__ProductService.__refresh()
          this.cerrarModal()
        } else {
          this.toastr.error(resp2.msn, 'Advertencia!', { timeOut: 4000 });
        }
      });
  }

  cerrarModal() {
    this.requestDataBoxTextHtml = 0
    this.n_vez = 0
    this.text = ""

    this.DATA_TO_ADD = {
      "id": 0,
      "name": "",
      "descriptionGeneral": "",
      "sellAfterOutOfStock": 0,
      "physicalProduct": 0,
      "useWithVariants": 0,
      "state": 0,
      "stateNormal": 0,
      "token": "",
    }

    this.DATA_TO_ADD_ERROR = {
      "id": "",
      "name": "",
      "descriptionGeneral": "",
      "sellAfterOutOfStock": "",
      "physicalProduct": "",
      "useWithVariants": "",
      "state": "",
    }

    this.__ModalProductService.ocultarModal();
  }

  getDataBoxTextHtml(e: any) {
    setTimeout(() => {
      this.requestDataBoxTextHtml = 0
      this.DATA_TO_ADD.descriptionGeneral = e;
      if (this.__ModalProductService.id > 0) {
        this.updateProduct()
      } else {
        this.addProduct()
      }
    }, 500);

  }

}
