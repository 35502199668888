import { Component, DoCheck, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { MenuService } from 'src/app/services/menu.service';
import { UsersService } from 'src/app/services/users.service';
import { ModalMenuItemService } from './modal-menu-item.service';

@Component({
  selector: 'app-modal-menu-item',
  templateUrl: './modal-menu-item.component.html',
  styles: [
  ]
})
export class ModalMenuItemComponent implements DoCheck {

  n_vez = 0
  btnPost = 1

  stateToShowSearchRoutes = 1
  urlSelected = ""

  MENU_ITEM_DATA = {
    idItemMenu: 0,
    idMenu: 0,
    name: "",
    idFather: 0,
    url: "",
    openOnAnotherPage: 0,
    token: "",
  }

  MENU_ITEM_DATA_ERROR = {
    name: "",
    url: "",
  }

  theme = {
    name: "light",
    background: "bg-light",
    background2: "bg-light",
    text: "text-dark",
    btnType1: "btn-primary",
  }

  constructor(
    public __UsersService: UsersService,
    private toastr: ToastrService,
    public __ModalMenuItemService: ModalMenuItemService,
    public __MenuService: MenuService,
  ) { }

  ngDoCheck() {
    if (this.__ModalMenuItemService.oculto == "" && this.__ModalMenuItemService.idMenu > 0 && this.__ModalMenuItemService.id == 0 && this.n_vez == 0) {

      this.n_vez = 1;
      this.MENU_ITEM_DATA.idMenu = this.__ModalMenuItemService.idMenu;
      this.MENU_ITEM_DATA.idFather = this.__ModalMenuItemService.idFather;
    }

    if (this.__ModalMenuItemService.oculto == "" && this.__ModalMenuItemService.idMenu > 0 && this.__ModalMenuItemService.id > 0 && this.n_vez == 0) {
      this.n_vez = 1;
      this.MENU_ITEM_DATA.idMenu = this.__ModalMenuItemService.idMenu;
      this.MENU_ITEM_DATA.idItemMenu = this.__ModalMenuItemService.id;

      this.getItemMenuById()
    }
  }

  getLink(e: any) {
    this.stateToShowSearchRoutes = 0
    this.urlSelected = e
    this.MENU_ITEM_DATA.url = e
    setTimeout(() => {
      this.stateToShowSearchRoutes = 1
    }, 300);
  }

  getItemMenuById(): Promise<boolean> {
    return new Promise((resolve, reject) => {

      let dataEnviar = {
        "id": this.MENU_ITEM_DATA.idItemMenu
      }

      this.__MenuService.getItemMenuById(dataEnviar)
        .subscribe((respuesta: any) => {
          if (respuesta.ok) {
            this.MENU_ITEM_DATA.name = respuesta.data_array[0].name
            this.MENU_ITEM_DATA.idFather = respuesta.data_array[0].idFather
            this.MENU_ITEM_DATA.url = respuesta.data_array[0].url
            this.urlSelected = respuesta.data_array[0].url
            this.MENU_ITEM_DATA.openOnAnotherPage = respuesta.data_array[0].openOnAnotherPage
            resolve(true);
            return;
          }
          // this.MENU.id = 0
          resolve(true);
        });
    })
  }

  add() {
    if (!this.validarCampo()) {
      console.log("NOPE")
      return;
    }

    this.MENU_ITEM_DATA.token = this.__UsersService.token;

    this.__MenuService.addItemMenu(this.MENU_ITEM_DATA)
      .subscribe((resp2: any) => {
        if (resp2.ok) {
          this.toastr.success("", 'Ítem Creado', { timeOut: 4500 });
          this.__MenuService.__refreshData();
          this.cerrarModal();
        } else {
          this.toastr.error(resp2.msn, 'Ocurrió un error!', { timeOut: 4500 });
        }

      });
  }

  update() {
    if (!this.validarCampo()) {
      console.log("NOPE")
      return;
    }

    this.MENU_ITEM_DATA.token = this.__UsersService.token;

    this.__MenuService.updateItemMenu(this.MENU_ITEM_DATA)
      .subscribe((resp2: any) => {
        console.log(resp2)
        if (resp2.ok) {
          this.toastr.success("", 'Ítem actualizado', { timeOut: 4500 });
          this.__MenuService.__refreshData();
          this.cerrarModal();
        } else {
          this.toastr.error(resp2.msn, 'Ocurrió un error!', { timeOut: 4500 });
        }

      });
  }

  validarCampo() {
    if (this.MENU_ITEM_DATA.name == "") {
      this.MENU_ITEM_DATA_ERROR.name = "Este campo no puede estar vacio";
      return false;
    }
    if ((this.MENU_ITEM_DATA.name).length > 30) {
      this.MENU_ITEM_DATA_ERROR.name = "Este campo no puede tener mas de 30 caracteres";
      return false;
    }

    if ((this.MENU_ITEM_DATA.url).length > 300) {
      this.MENU_ITEM_DATA_ERROR.url = "Este campo no puede tener mas de 300 caracteres";
      return false;
    }

    if (this.MENU_ITEM_DATA.idMenu == 0 || !this.MENU_ITEM_DATA.idMenu) {
      this.toastr.error("Actualiza y prueba de  nuevo", 'Ocurrió un error!', { timeOut: 4500 });
      return false;
    }

    this.limpiar();
    return true;
  }

  limpiar() {
    this.MENU_ITEM_DATA_ERROR = {
      "name": "",
      "url": "",
    }
  }

  cerrarModal() {
    this.n_vez = 0
    this.btnPost = 1

    this.stateToShowSearchRoutes = 1
    this.urlSelected = ""

    this.MENU_ITEM_DATA = {
      idItemMenu: 0,
      idMenu: 0,
      name: "",
      idFather: 0,
      url: "",
      openOnAnotherPage: 0,
      token: "",
    }

    this.MENU_ITEM_DATA_ERROR = {
      name: "",
      url: "",
    }

    this.__ModalMenuItemService.ocultarModal()
  }

}
