import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class ModalComponentService {

  public oculto = "oculto";
  public page = "";
  public forWhat = "";

  constructor(
    private toastr: ToastrService,
  ) { }

  ocultarModal() {
    this.oculto = "oculto";
    this.page = "";
  }
  mostrarModal(forWhat: string, page: string) {
    if (!forWhat || forWhat == "" || !page || page == "") {
      this.toastr.error("Ocurrió un error al mostrar el modal, por favor actualiza la página e intenta de nuevo.", 'Advertencia!', { timeOut: 4000 });
      return
    }
    this.oculto = "";
    this.forWhat = forWhat;
    this.page = page;
  }
}
