<div class="fondo-negro animated fadeIn" *ngIf="__ModalOurPricesService.oculto!=='oculto'">

    <div class="modal" style="display:block;" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-lg" role="document">

            <!-- UPDATE -->
            <div class="modal-content {{theme.background2}} {{theme.text}}" style="overflow: auto;height: 660px;">
                <div class="modal-header">
                    <h2 class="modal-title">Actualizar "nuestros precios"</h2>

                    <button (click)="cerrarModal()" type="button" class="close text-danger font-large-2"
                        aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body" align="center">

                    <div [hidden]="stateGeneral===0" class="card-body">

                        <div class="form-group">
                            <label class="col-12 col-form-label font-medium-4 text-left font-weight-bold">Título</label>
                            <div class="border border-primary">
                                <app-rich-text [requestData]="requestDataBoxTextHtml1"
                                    [mydataTextHtml]="ourPrice_DATA.title" (emitOnChange)="getDataBoxTextHtml($event)">
                                </app-rich-text>
                            </div>
                        </div>

                        <div class="form-group">
                            <label
                                class="col-12 col-form-label font-medium-4 text-left font-weight-bold">Descripción</label>
                            <div class="border border-primary">
                                <app-rich-text [requestData]="requestDataBoxTextHtml2"
                                    [mydataTextHtml]="ourPrice_DATA.description"
                                    (emitOnChange)="getDataBoxTextHtml2($event)">
                                </app-rich-text>
                            </div>
                        </div>

                        <div *ngIf="ourPrice_DATA.idCFM>0" class="form-group row">
                            <label
                                class="col-md-3 label-control font-medium-4 text-left font-weight-bold mt-2">Imagen</label>

                            <p *ngIf="(!ourPrice_DATA.idImg || +ourPrice_DATA.idImg===0)&&stateChooseImg===0"
                                class="cursor-pointer pt-2 text-warning" (click)="stateChooseImg = 1">
                                Seleccionar imagen</p>
                            <div *ngIf="(!ourPrice_DATA.idImg || +ourPrice_DATA.idImg===0)&&stateChooseImg===1"
                                class="col-md-9 box-shadow-2 bg-white p-2 mb-3">
                                <div class="w-100 text-right">
                                    <span (click)="stateChooseImg = 0" class="text-danger font-large-2 cursor-pointer"
                                        aria-hidden="true">&times;</span>
                                </div>
                                <p class="text-left">Busca, selecciona o agrega una imagen</p>
                                <app-manage-file (emitImageChoosed)="setImg($event)"></app-manage-file>
                            </div>
                            <div *ngIf="ourPrice_DATA.idImg && ourPrice_DATA.idImg>0" class="col-md-9">
                                <button type="button" (click)="removeImgById(ourPrice_DATA.idImg)"
                                    class="btn btn-danger mb-0"
                                    style="position: relative;display: block;margin: auto;left: 145px;top: 25px;">
                                    <i class="fa fa-times"></i>
                                </button>

                                <img style="width: 295px;display: block;margin: auto;" src="{{ourPrice_DATA.urlImg}}">
                            </div>
                        </div>

                        <div class="form-group row mt-1">
                            <label class="col-md-3 label-control font-medium-4 text-left font-weight-bold mt-2">
                                Color de fondo (caja del texto)
                            </label>
                            <div class="col-md-9">
                                <input class="w-100" [(ngModel)]="ourPrice_DATA.backgroungColor" name="backgroungColor"
                                    [(colorPicker)]="ourPrice_DATA.backgroungColor"
                                    [style.background]="ourPrice_DATA.backgroungColor" />
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-md-3 label-control font-medium-1 font-weight-bold mt-2 text-left">
                                Partículas
                            </label>
                            <div class="col-md-9">
                                <select [(ngModel)]="ourPrice_DATA.backgroundParticles" name="backgroundParticles"
                                    class="form-control {{theme.background}} {{theme.text}}">
                                    <option value="0">No</option>
                                    <option value="1">Si</option>
                                </select>
                            </div>
                        </div>


                    </div>
                    <div *ngIf="stateGeneral===0" class="content-wrapper">
                        <i style="position: absolute;top: 170px;left: 50%;margin: -35px 0 0 -35px;"
                            class="fa fa-spinner fa-spin fa-3x fa-fw"></i>
                    </div>


                </div>
                <div *ngIf="btnUpdate===1" class="modal-footer">
                    <button (click)="cerrarModal()" type="button" class="btn btn-danger">cerrar</button>

                    <button (click)="eventUpdate(1)" type="button" class="btn btn-primary">Actualizar</button>
                </div>
            </div>

        </div>
    </div>
</div>