<div *ngIf="vista==='COMPLETE'" class="w-100 heightForHeaderHidden" style="height: 96px;"></div>
<header class="header" [style.backgroundColor]="DATA.backgroundColor" style="
width: 100%;z-index: 999;top: 0px;" [ngStyle]="{'position': (vista==='COMPLETE') ? 'fixed' : 'relative'}">

    <!--=======  header bottom  =======-->

    <div class="header-bottom pt-md-40 pb-md-40 pt-sm-40 pb-sm-40">
        <div class="container wide">

            <!--=======  header bottom container  =======-->

            <div class="header-bottom-container">

                <!--=======  logo with off canvas  =======-->

                <div class="logo-with-offcanvas d-flex">

                    <!--=======  logo   =======-->

                    <div class="logo">
                        <a [routerLink]="vista==='COMPLETE' ? ['/'] : []">
                            <img src="{{DATA.routeImage}}" class="img-fluid" alt=""
                                style="width: auto;max-height: 96px;">
                        </a>
                    </div>

                    <!--=======  End of logo   =======-->
                </div>

                <!--=======  End of logo with off canvas  =======-->

                <!--=======  header bottom navigation  =======-->

                <div class="header-bottom-navigation">
                    <div class="site-main-nav d-none d-lg-block">
                        <nav class="site-nav center-menu">
                            <ul>
                                <ng-container *ngFor="let d of ITEMS_MENU; index as i">
                                    <li *ngIf="d['subMenuQuantity']=== 0">
                                        <a *ngIf="d['displayAs']=== 'DIRECT'" [style.color]="DATA.colorText"
                                            [routerLink]="(vista==='COMPLETE' && d['url']!=='') ? [d['url']] : []">{{d["name"]}}</a>

                                        <a *ngIf="d['displayAs']!== 'DIRECT'" [style.color]="DATA.colorText"
                                            (click)="goToUrlCustom(d['displayAs'],d['url'],d['openOnAnotherPage'])">{{d["name"]}}</a>
                                    </li>
                                    <li *ngIf="d['subMenuQuantity']>0" class="menu-item-has-children">
                                        <a *ngIf="d['displayAs']=== 'DIRECT'" [style.color]="DATA.colorText"
                                            [routerLink]="(vista==='COMPLETE' && d['url']!=='') ? [d['url']] : []">{{d["name"]}}</a>

                                        <a *ngIf="d['displayAs']!== 'DIRECT'" [style.color]="DATA.colorText"
                                            (click)="goToUrlCustom(d['displayAs'],d['url'],d['openOnAnotherPage'])">{{d["name"]}}</a>

                                        <ul class="sub-menu border" style="width: 250px;top: 95px;">
                                            <ng-container *ngFor="let subItem of d['subMenu']">
                                                <li class="d-block p-3 cursor-pointer"
                                                    style="border-bottom: 2px solid #ccc;">
                                                    <a *ngIf="subItem['displayAs']=== 'DIRECT'"
                                                        [routerLink]="(vista==='COMPLETE' && subItem['url']!=='') ? [subItem['url']] : []"
                                                        [style.color]="DATA.colorText">{{subItem["name"]}}</a>
                                                    <a *ngIf="subItem['displayAs']!== 'DIRECT'"
                                                        [style.color]="DATA.colorText"
                                                        (click)="goToUrlCustom(subItem['displayAs'],subItem['url'],subItem['openOnAnotherPage'])">{{subItem["name"]}}</a>
                                                </li>
                                            </ng-container>

                                        </ul>
                                    </li>
                                </ng-container>
                                <!-- <li>
                                    <a [style.color]="DATA.colorText" [routerLink]="['/']">Home</a>
                                </li>
                                
                                <li class="menu-item-has-children">
                                    <a [style.color]="DATA.colorText" [routerLink]="['/products']">Productos</a>
                                    <ul class="sub-menu" style="width: 150px;">
                                        <li class="d-block p-2" style="border-bottom: 2px solid #ccc;">
                                            shorts
                                        </li>
                                        <li class="d-block p-2" style="border-bottom: 2px solid #ccc;">
                                            pants
                                        </li>
                                    </ul>
                                </li> -->
                            </ul>
                        </nav>
                    </div>
                </div>

                <!--=======  End of header bottom navigation  =======-->

                <!--=======  headeer right container  =======-->

                <div class="header-right-container">


                    <div class="header-right-icons d-flex justify-content-end align-items-center h-100">
                        <div *ngIf="DATA.displaySearch===1" class="single-icon search">
                            <a id="search-icon">
                                <i [style.color]="DATA.colorText" class="ion-ios-search-strong"></i>
                            </a>
                        </div>

                        <div *ngIf="statusIsLoggedOn===0" class="single-icon user-login">
                            <a [routerLink]="vista==='COMPLETE' ? ['/login'] : []">
                                <i class="ion-android-person text-danger"></i>
                            </a>
                        </div>

                        <div *ngIf="statusIsLoggedOn===1" class="single-icon user-login">
                            <a [routerLink]="vista==='COMPLETE' ? ['/myAccount'] : []">
                                <i class="ion-android-person text-primary"></i>
                            </a>
                        </div>

                        <div *ngIf="DATA.displayWishList===1" class="single-icon wishlist">
                            <a (click)="vista==='COMPLETE' ? toggleActiveWishListState(): false"
                                id="offcanvas-wishlist-icon">
                                <i [style.color]="DATA.colorText" class="ion-android-favorite-outline"></i>
                                <span class="count">2</span>
                            </a>
                        </div>

                        <app-cart-multifunction *ngIf="DATA.displayCart===1" [mode]="'icon'"
                            (emitOnChange_menuStateToShow)="vista==='COMPLETE' ? toggleActiveMenu($event): false">
                        </app-cart-multifunction>
                    </div>

                </div>

                <!--=======  End of headeer right container  =======-->


            </div>

            <!--=======  End of header bottom container  =======-->

            <!-- Mobile Navigation Start Here -->

            <div class="site-mobile-navigation d-block d-lg-none">
                <div id="dl-menu" class="dl-menuwrapper site-mobile-nav">
                    <!--Site Mobile Menu Toggle Start-->
                    <button (click)="vista==='COMPLETE' ? toggleActiveMenuMobileState() : false"
                        class="dl-trigger hamburger hamburger--spin" style="top: -52px;">
                        <span class="hamburger-box">
                            <span class="hamburger-inner"></span>
                        </span>
                    </button>
                </div>
            </div>

            <!-- Mobile Navigation End Here -->

        </div>
    </div>

    <!--=======  End of header bottom  =======-->
</header>

<div *ngIf="vista==='COMPLETE'" class="wishlist-overlay {{statusToShow.activeWishListState}}" id="wishlist-overlay">
    <div class="wishlist-overlay-close inactive"></div>
    <div *ngIf="statusToShow.activeWishListState!==''" class="wishlist-overlay-content">
        <span class="close-icon" id="wishlist-close-icon">
            <a (click)="toggleActiveWishListState()">
                <i class="ion-android-close"></i>
            </a>
        </span>

        <div class="offcanvas-cart-content-container">
            <h3 class="cart-title">Wishlist</h3>

            <div class="cart-product-wrapper">
                <div class="cart-product-container  ps-scroll">
                    <!--=======  single cart product  =======-->

                    <div class="single-cart-product">
                        <span class="cart-close-icon">
                            <a href="#"><i class="ti-close"></i></a>
                        </span>
                        <div class="image">
                            <a href="shop-product-basic.html">
                                <img src="https://via.placeholder.com/150" class="img-fluid" alt="">
                            </a>
                        </div>
                        <div class="content">
                            <h5><a href="shop-product-basic.html">Dark Brown Leather Watch</a></h5>
                            <p><span class="main-price discounted">$200.00</span> <span
                                    class="discounted-price">$180.00</span></p>

                        </div>
                    </div>

                    <!--=======  End of single cart product  =======-->
                    <!--=======  single cart product  =======-->

                    <div class="single-cart-product">
                        <span class="cart-close-icon">
                            <a href="#"><i class="ti-close"></i></a>
                        </span>
                        <div class="image">
                            <a href="shop-product-basic.html">
                                <img src="https://via.placeholder.com/150" class="img-fluid" alt="">
                            </a>
                        </div>
                        <div class="content">
                            <h5><a href="shop-product-basic.html">Dining Chair</a></h5>
                            <p><span class="main-price discounted">$300.00</span> <span
                                    class="discounted-price">$220.00</span></p>

                        </div>
                    </div>

                    <!--=======  End of single cart product  =======-->
                    <!--=======  single cart product  =======-->

                    <div class="single-cart-product">
                        <span class="cart-close-icon">
                            <a href="#"><i class="ti-close"></i></a>
                        </span>
                        <div class="image">
                            <a href="shop-product-basic.html">
                                <img src="https://via.placeholder.com/150" class="img-fluid" alt="">
                            </a>
                        </div>
                        <div class="content">
                            <h5><a href="shop-product-basic.html">Creative Wooden Stand</a></h5>
                            <p><span class="main-price discounted">$100.00</span> <span
                                    class="discounted-price">$80.00</span></p>

                        </div>
                    </div>

                    <!--=======  End of single cart product  =======-->
                    <!--=======  single cart product  =======-->

                    <div class="single-cart-product">
                        <span class="cart-close-icon">
                            <a href="#"><i class="ti-close"></i></a>
                        </span>
                        <div class="image">
                            <a href="shop-product-basic.html">
                                <img src="https://via.placeholder.com/150" class="img-fluid" alt="">
                            </a>
                        </div>
                        <div class="content">
                            <h5><a href="shop-product-basic.html">Dark Brown Leather Watch</a></h5>
                            <p><span class="main-price discounted">$200.00</span> <span
                                    class="discounted-price">$180.00</span></p>

                        </div>
                    </div>

                    <!--=======  End of single cart product  =======-->
                    <!--=======  single cart product  =======-->

                    <div class="single-cart-product">
                        <span class="cart-close-icon">
                            <a href="#"><i class="ti-close"></i></a>
                        </span>
                        <div class="image">
                            <a href="shop-product-basic.html">
                                <img src="https://via.placeholder.com/150" class="img-fluid" alt="">
                            </a>
                        </div>
                        <div class="content">
                            <h5><a href="shop-product-basic.html">Creative Wooden Stand</a></h5>
                            <p><span class="main-price discounted">$200.00</span> <span
                                    class="discounted-price">$180.00</span></p>

                        </div>
                    </div>

                    <!--=======  End of single cart product  =======-->
                </div>

                <!--=======  cart buttons  =======-->

                <div class="cart-buttons">
                    <a href="shop-wishlist.html">view wishlist</a>
                </div>

                <!--=======  End of cart buttons  =======-->
            </div>
        </div>

    </div>
</div>

<div *ngIf="vista==='COMPLETE'" class="menuMobile-overlay {{statusToShow.activeMenuMobileState}}"
    id="menuMobile-overlay">
    <div class="menuMobile-overlay-close inactive"></div>

    <div *ngIf="statusToShow.activeMenuMobileState!==''" class="menuMobile-overlay-content">
        <!--=======  close icon  =======-->

        <span class="close-icon" id="menuMobile-close-icon">
            <a (click)="toggleActiveMenuMobileState()">
                <i class="ion-android-close"></i>
            </a>
        </span>

        <!--=======  End of close icon  =======-->

        <!--=======  offcanvas cart content container  =======-->

        <div class="offcanvas-menuMobile-content-container">
            <h3 class="">menu</h3>
        </div>

        <ul>
            <ng-container *ngFor="let d of ITEMS_MENU; index as i">
                <li *ngIf="d['subMenuQuantity']=== 0" style="padding: 10px 5px;">
                    <a *ngIf="d['displayAs']=== 'DIRECT'" class="font-weight-bold" [style.color]="DATA.colorText"
                        [routerLink]="(vista==='COMPLETE' && d['url']!=='') ? [d['url']] : []">{{d["name"]}}</a>

                    <a *ngIf="d['displayAs']!== 'DIRECT'" class="font-weight-bold" [style.color]="DATA.colorText"
                        (click)="goToUrlCustom(d['displayAs'],d['url'],d['openOnAnotherPage'])">{{d["name"]}}</a>
                </li>
                <li *ngIf="d['subMenuQuantity']>0" class="menu-item-has-children" style="padding: 10px 5px;">
                    <a *ngIf="d['displayAs']=== 'DIRECT'" class="font-weight-bold" [style.color]="DATA.colorText"
                        [routerLink]="(vista==='COMPLETE' && d['url']!=='') ? [d['url']] : []">{{d["name"]}}</a>

                    <a *ngIf="d['displayAs']!== 'DIRECT'" class="font-weight-bold" [style.color]="DATA.colorText"
                        (click)="goToUrlCustom(d['displayAs'],d['url'],d['openOnAnotherPage'])">{{d["name"]}}</a>

                    <i class="fa fa-plus float-right mt-2" (click)="toggleSubMenus(d['id'])" aria-hidden="true"></i>
                    <ul class="w-100" style="display: none;" id="boxSubMenuOfMenu_{{d['id']}}">
                        <ng-container *ngFor="let subItem of d['subMenu']">
                            <li class="d-block p-1 cursor-pointer" style="border-bottom: 2px solid #ccc;">
                                <a *ngIf="subItem['displayAs']=== 'DIRECT'"
                                    [routerLink]="(vista==='COMPLETE' && subItem['url']!=='' && subItem['url']) ? [subItem['url']] : []"
                                    [style.color]="DATA.colorText">{{subItem["name"]}}</a>
                                <a *ngIf="subItem['displayAs']!== 'DIRECT'" [style.color]="DATA.colorText"
                                    (click)="goToUrlCustom(subItem['displayAs'],subItem['url'],subItem['openOnAnotherPage'])">{{subItem["name"]}}</a>
                            </li>
                        </ng-container>
                    </ul>
                </li>
            </ng-container>
        </ul>
    </div>

</div>

<app-cart-multifunction *ngIf="vista==='COMPLETE'" [mode]="'floatCart'" [activeMenuState]="statusToShow.activeMenuState"
    (emitOnChange_menuStateToShow)="toggleActiveMenu($event)">
</app-cart-multifunction>