<div class="fondo-negro animated fadeIn" [ngClass]="__ModalChooseAndSetMenuService.oculto">

    <div class="modal" style="display:block;" tabindex="-1" role="dialog">

        <div class="modal-dialog" role="document">
            <div class="modal-content {{theme.background2}} {{theme.text}}" style="overflow: auto;height: 590px;">
                <div class="modal-header">
                    <h2 class="modal-title">Establecer menú</h2>

                    <button (click)="cerrarModal()" type="button" class="close text-danger font-large-2"
                        aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body" align="center">

                    <div class="card-body">

                        <!-- <div class="form-group row">
                            <div class="col-md-12">
                                <select [(ngModel)]="DATA_TO_ADD.idComponent" (change)="displayImage()"
                                    name="users-list-role" class="form-control">
                                    <option *ngFor="let comp of ARRAY_COMPONENTS" value="{{comp['id']}}">
                                        <input id="_{{comp['id']}}" type="text" style="display: none;"
                                            value="{{comp['urlImage']}}">
                                        {{comp['name']}}
                                    </option>
                                </select>
                            </div>
                        </div> -->

                        <div class="form-group row">
                            <div class="col-md-12">
                                <img class="w-100" id="showImgComponent" src="">
                            </div>
                        </div>

                    </div>


                </div>
                <div class="modal-footer">
                    <button (click)="save()" type="button" class="btn btn-primary btn-lg"
                        style="padding-top: 2px !important;">Guardar</button>
                </div>
            </div>
        </div>


    </div>
</div>