import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CipHeaderComponent } from './cfm-header/cip-header.component';
import { DatePipe } from '@angular/common';
import { ColorPickerModule } from 'ngx-color-picker';
import { FormsModule } from '@angular/forms';
import { CfmFooterComponent } from './cfm-footer/cfm-footer.component';
import { CipSlidershowComponent } from './cfm-slidershow/cip-slidershow.component';
import { CipImageWithTextOverlayComponent } from './cfm-image-with-text-overlay/cip-image-with-text-overlay.component';
import { CipImageWithTextComponent } from './cfm-image-with-text/cip-image-with-text.component';
import { CfmOurPricesComponent } from './cfm-our-prices/cfm-our-prices.component';
import { CfmContactUsComponent } from './cfm-contact-us/cfm-contact-us.component';
import { CfmSectionTextComponent } from './cfm-section-text/cfm-section-text.component';
import { ComponentsModule } from '../components/components.module';

@NgModule({
  declarations: [
    CipHeaderComponent,
    CfmFooterComponent,
    CipSlidershowComponent,
    CipImageWithTextOverlayComponent,
    CipImageWithTextComponent,
    CfmOurPricesComponent,
    CfmContactUsComponent,
    CfmSectionTextComponent,
  ],
  imports: [
    CommonModule,
    ColorPickerModule,
    FormsModule,
    ComponentsModule
  ],
  providers: [DatePipe]
})
export class ComponentsForManageModule { }
