<div class="fondo-negro animated fadeIn" [ngClass]="__ModalImageService.oculto">

    <div class="modal" style="display:block;" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-lg" role="document">

            <!-- CREATE -->
            <div *ngIf="__ModalImageService.id>0" class="modal-content {{theme.background2}} {{theme.text}}">
                <div class="modal-header">
                    <h2 class="modal-title">Subir imagen</h2>

                    <button (click)="cerrarModal()" type="button" class="close text-danger font-large-2"
                        aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body" align="center">

                    <div class="form-group row">
                        <button type="button" class="w-85 m-auto" style="position: relative;">
                            <span style="position: absolute;transform: translate(-50%, -50%);top: 50%;left: 50%;">
                                Subir imagen
                            </span>
                            <input class="w-100" style="opacity: 0;height: 90px;" id="fileUpload_IMAGEN" type="file"
                                name="file" (change)="onSelect($event)" accept="image/png, image/jpeg, image/jpg">
                        </button>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>