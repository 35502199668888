import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ModalItemOurPricesService {

  public oculto = "oculto";
  public id = 0;
  public idCFM = 0;

  constructor() { }

  ocultarModal() {
    this.oculto = "oculto";
    this.id = 0;
    this.idCFM = 0;
  }
  mostrarModal(id: number = 0, idCFM = 0) {
    this.oculto = "";
    this.id = id;
    this.idCFM = idCFM;
  }
}
