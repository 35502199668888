<div class="fondo-negro animated fadeIn" [ngClass]="__ModalComponentService.oculto">

    <div class="modal" style="display:block;" tabindex="-1" role="dialog">

        <div class="modal-dialog" role="document">
            <div class="modal-content {{theme.background2}} {{theme.text}}" style="overflow: auto;height: 590px;">
                <div class="modal-header">
                    <h2 *ngIf="__ModalComponentService.forWhat === 'add'" class="modal-title">Agregar componente</h2>
                    <h2 *ngIf="__ModalComponentService.forWhat === 'changeOrder'" class="modal-title">Cambiar orden</h2>

                    <button (click)="cerrarModal()" type="button" class="close text-danger font-large-2"
                        aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body" align="center">

                    <div *ngIf="__ModalComponentService.forWhat === 'changeOrder'" class="card-body">
                        <app-drag-and-drop *ngIf="stateToShowGeneral===1 && ARRAY_COMPONENTS.length>0"
                            (emitNewDataOrdered)="changeOrderPosition($event)" [data]="ARRAY_COMPONENTS"
                            [config]="{id: 'cip_id',nameKeyToShow: 'c_name'}" [type]="'image'" [keyToShow]="'c_name'">
                        </app-drag-and-drop>
                        <p *ngIf="stateToShowGeneral===1 && ARRAY_COMPONENTS.length===0">No hay datos</p>
                    </div>

                    <div *ngIf="__ModalComponentService.forWhat === 'add'" class="card-body">
                        <div class="form-group row">
                            <div class="col-md-12">
                                <select [(ngModel)]="DATA_TO_ADD.idComponent" (change)="displayImage()"
                                    name="users-list-role" class="form-control">
                                    <option *ngFor="let comp of ARRAY_COMPONENTS" value="{{comp['id']}}">
                                        <input id="_{{comp['id']}}" type="text" style="display: none;"
                                            value="{{comp['urlImage']}}">
                                        {{comp['name']}}
                                    </option>
                                </select>
                            </div>
                        </div>

                        <div class="form-group row mt-2">
                            <div class="col-md-12">
                                <img class="w-100" id="showImgComponent" src="">
                            </div>
                        </div>
                    </div>


                </div>
                <div class="modal-footer">
                    <button *ngIf="__ModalComponentService.forWhat === 'add'" (click)="addComponentToPage()"
                        type="button" class="btn btn-primary btn-lg"
                        style="padding-top: 2px !important;">Agregar</button>
                </div>
            </div>
        </div>


    </div>
</div>