import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ModalVariantService {

  public oculto = "oculto";
  public idProduct = 0;
  public id = 0;

  constructor() { }

  ocultarModal() {
    this.oculto = "oculto";
    this.idProduct = 0;
    this.id = 0;
  }
  mostrarModal(idProduct: number = 0, id: number = 0) {
    this.oculto = "";
    this.idProduct = idProduct;
    this.id = id;
  }
}
