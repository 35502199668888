import { Component, DoCheck, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { SectionTextService } from 'src/app/services/section-text.service';
import { UsersService } from 'src/app/services/users.service';
import { ModalSectionTextService } from './modal-section-text.service';

@Component({
  selector: 'app-modal-section-text',
  templateUrl: './modal-section-text.component.html',
  styles: [
  ]
})
export class ModalSectionTextComponent implements DoCheck {

  n_vez = 0
  stateGeneral = 0
  btnUpdate = 1

  boxMyRichText = {
    display: 0,
    requestData: 0
  }

  collapseTitle = ""
  collapseDescription = ""

  TEXTS_DATA = {
    id: 0,
    idCFM: 0,

    titleText: "",
    titleColor: "",
    titleFontSize: 0,
    titleBoldText: "unset",

    descriptionText: "",
    descriptionColor: "",
    descriptionFontSize: 0,
    descriptionBoldText: "unset",

    backgroundColor: "",
    backgroundColorTextBox: "",
    state: 1,
  }

  ERROR_TEXTS_DATA = {
    backgroundColor: "",
    backgroundColorTextBox: "",
  }

  theme = {
    name: "light",
    background: "bg-light",
    background2: "bg-light",
    text: "text-dark",
    btnType1: "btn-primary",
  }

  constructor(
    public __UsersService: UsersService,
    public __SectionTextService: SectionTextService,
    private toastr: ToastrService,
    public __ModalSectionTextService: ModalSectionTextService,
  ) { }

  ngDoCheck() {

    if (this.__ModalSectionTextService.oculto == "" && this.__ModalSectionTextService.idCFM > 0 && this.n_vez == 0) {
      this.getByIdCFM().then(
        () => {

        }
      ).catch(error => console.error("Error en la promesa", error));
      this.n_vez = 1;
    }
  }

  getByIdCFM(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.boxMyRichText.display = 0
      this.stateGeneral = 0
      let dataEnviar = {
        "idCFM": this.__ModalSectionTextService.idCFM,
        "token": this.__UsersService.token
      }

      this.__SectionTextService.getByIdCFM(dataEnviar)
        .subscribe((respuesta: any) => {
          this.boxMyRichText.display = 1
          this.stateGeneral = 1
          if (respuesta.ok && respuesta.data_array.length > 0) {
            this.TEXTS_DATA.id = respuesta.data_array[0].id
            this.TEXTS_DATA.titleText = (respuesta.data_array[0].titleText) ? respuesta.data_array[0].titleText : ""
            this.TEXTS_DATA.titleColor = (respuesta.data_array[0].titleColor) ? respuesta.data_array[0].titleColor : ""
            this.TEXTS_DATA.titleFontSize = (respuesta.data_array[0].titleFontSize) ? respuesta.data_array[0].titleFontSize : ""
            this.TEXTS_DATA.titleBoldText = respuesta.data_array[0].titleBoldText
            this.TEXTS_DATA.descriptionText = (respuesta.data_array[0].descriptionText) ? respuesta.data_array[0].descriptionText : ""
            this.TEXTS_DATA.descriptionColor = (respuesta.data_array[0].descriptionColor) ? respuesta.data_array[0].descriptionColor : ""
            this.TEXTS_DATA.descriptionFontSize = (respuesta.data_array[0].descriptionFontSize) ? respuesta.data_array[0].descriptionFontSize : ""
            this.TEXTS_DATA.descriptionBoldText = respuesta.data_array[0].descriptionBoldText
            this.TEXTS_DATA.backgroundColorTextBox = respuesta.data_array[0].backgroundColorTextBox
            this.TEXTS_DATA.backgroundColor = (respuesta.data_array[0].backgroundColor) ? respuesta.data_array[0].backgroundColor : ""

            resolve(true);
            return;
          }
          resolve(true);
        });
    })
  }

  eventUpdate() {
    this.btnUpdate = 0
    this.boxMyRichText.requestData = 1;
    this.stateGeneral = 0

    setTimeout(() => {
      this.update()
    }, 2000);
    return;
  }

  limpiar() {
    this.ERROR_TEXTS_DATA = {
      backgroundColor: "",
      backgroundColorTextBox: "",
    }
  }

  validarCampo() {
    this.limpiar();

    if ((this.TEXTS_DATA.backgroundColor).length > 25) {
      this.ERROR_TEXTS_DATA.backgroundColor = "Este campo no puede tener mas de 25 caracteres";
      return false;
    }

    if ((this.TEXTS_DATA.backgroundColorTextBox).length > 25) {
      this.ERROR_TEXTS_DATA.backgroundColorTextBox = "Este campo no puede tener mas de 25 caracteres";
      return false;
    }


    this.limpiar();
    return true;
  }

  update() {

    if (!this.validarCampo()) {
      this.stateGeneral = 1
      console.log("NOPE")
      return;
    }

    let dataEnviar = {
      "idCFM": this.__ModalSectionTextService.idCFM,

      "titleText": this.TEXTS_DATA.titleText,
      "titleFontSize": this.TEXTS_DATA.titleFontSize,
      "titleColor": this.TEXTS_DATA.titleColor,
      "titleBoldText": this.TEXTS_DATA.titleBoldText,

      "descriptionText": this.TEXTS_DATA.descriptionText,
      "descriptionColor": this.TEXTS_DATA.descriptionColor,
      "descriptionFontSize": this.TEXTS_DATA.descriptionFontSize,
      "descriptionBoldText": this.TEXTS_DATA.descriptionBoldText,

      "backgroundColor": this.TEXTS_DATA.backgroundColor,
      "backgroundColorTextBox": this.TEXTS_DATA.backgroundColorTextBox,
      "state": this.TEXTS_DATA.state,

      "token": this.__UsersService.token
    }

    this.__SectionTextService.addOrUpdate(dataEnviar)
      .subscribe((respuesta: any) => {
        this.btnUpdate = 1
        this.stateGeneral = 1
        if (respuesta.ok) {
          this.__SectionTextService.__refresh()
          this.toastr.success("Actualizado", 'Éxito!', { timeOut: 7000 });
          this.cerrarModal()
          return
        }
        this.toastr.error("ocurrió un error", 'Advertencia!', { timeOut: 7000 });
      });
  }

  toggleCollapseTitle() {
    if (this.collapseTitle == "") {
      this.collapseTitle = "show"
    } else {
      this.collapseTitle = ""
    }
  }

  toggleCollapseDescription() {
    if (this.collapseDescription == "") {
      this.collapseDescription = "show"
    } else {
      this.collapseDescription = ""
    }
  }

  setTextFromMyRichText(e: any, to: string) {
    setTimeout(() => {
      if (to == "title") {
        this.boxMyRichText.display = 0

        this.TEXTS_DATA.titleText = e.text
        this.TEXTS_DATA.titleFontSize = e.fontSizeText
        this.TEXTS_DATA.titleColor = e.colorText
        this.TEXTS_DATA.titleBoldText = e.boldText
        return
      }

      if (to == "description") {
        this.boxMyRichText.display = 0

        this.TEXTS_DATA.descriptionText = e.text
        this.TEXTS_DATA.descriptionFontSize = e.fontSizeText
        this.TEXTS_DATA.descriptionColor = e.colorText
        this.TEXTS_DATA.descriptionBoldText = e.boldText
        return
      }

    }, 500);
  }

  cerrarModal() {
    this.n_vez = 0
    this.stateGeneral = 0
    this.btnUpdate = 1

    this.boxMyRichText = {
      display: 0,
      requestData: 0
    }

    this.collapseTitle = ""
    this.collapseDescription = ""

    this.TEXTS_DATA = {
      id: 0,
      idCFM: 0,

      titleText: "",
      titleColor: "",
      titleFontSize: 0,
      titleBoldText: "unset",

      descriptionText: "",
      descriptionColor: "",
      descriptionFontSize: 0,
      descriptionBoldText: "unset",

      backgroundColor: "",
      backgroundColorTextBox: "",
      state: 1,
    }

    this.ERROR_TEXTS_DATA = {
      backgroundColor: "",
      backgroundColorTextBox: "",
    }
    this.__ModalSectionTextService.ocultarModal()
  }

}
