import { RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent } from './store/page-not-found/page-not-found.component';
import { StoreComponent } from './store/store.component';
import { AdminComponent } from './admin/admin.component';
import { CheckIfIsLogoutGuard } from './services/guard/check-if-is-logout.guard';

const appRoutes: Routes = [
  {
    path: 'admin',
    component: AdminComponent,
    canActivate: [CheckIfIsLogoutGuard],
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule)
  },
  {
    path: "",
    component: StoreComponent,
    // canActivate:[LoginGuardGuard],
    loadChildren: () => import('./store/store.module').then(m => m.StoreModule)
  },
  { path: '**', component: PageNotFoundComponent }
  // { path: '**', component: NopagefoundComponent }
];


export const APP_ROUTES = RouterModule.forRoot(appRoutes, { useHash: true, relativeLinkResolution: 'legacy' });