import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { UsersService } from 'src/app/services/users.service';
import { ModalChooseAndSetMenuService } from './modal-choose-and-set-menu.service';

@Component({
  selector: 'app-modal-choose-and-set-menu',
  templateUrl: './modal-choose-and-set-menu.component.html',
  styles: [
  ]
})
export class ModalChooseAndSetMenuComponent {

  n_vez = 0

  ARRAY_COMPONENTS = []

  DATA_TO_ADD = {
    idComponent: null
  }

  theme = {
    name: "light",
    background: "bg-light",
    background2: "bg-light",
    text: "text-dark",
    btnType1: "btn-primary",
  }

  constructor(
    public __UsersService: UsersService,
    private toastr: ToastrService,
    public __ModalChooseAndSetMenuService: ModalChooseAndSetMenuService,
  ) { }

  save() {

  }

  cerrarModal() {

  }

}
