import { Component, DoCheck, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-my-rich-text',
  templateUrl: './my-rich-text.component.html',
  styles: [
  ]
})
export class MyRichTextComponent implements OnInit, DoCheck {
  @Output() emitOnChange = new EventEmitter<any>();
  @Input() requestData = 0;

  @Input() colorText = "";
  @Input() fontSizeText = 0;
  @Input() boldText = "unset";
  @Input() text = "";
  @Input() renderFor = "span";
  @Input() backgroundColor = "";
  @Input() border = 0;
  @Input() align = "center";
  @Input() urlSelected = "";

  countInicio = 0
  count1 = 0
  stateToShowSearchRoutes = 1

  ItemsToSend = {
    colorText: "",
    fontSizeText: 0,
    boldText: "unset",
    text: "",
    renderFor: "span",
    backgroundColor: "",
    border: 0,
    align: "center",
    url: "",
  }

  textItemError = {
    colorText: "",
    fontSizeText: "",
    boldText: "",
    text: "",
    backgroundColor: "",
  }

  theme = {
    name: "light",
    background: "bg-light",
    background2: "bg-light",
    text: "text-dark",
    btnType1: "btn-primary",
  }

  constructor() { }

  getLink(e: any) {
    this.stateToShowSearchRoutes = 0
    this.urlSelected = e
    this.ItemsToSend.url = e
    setTimeout(() => {
      this.stateToShowSearchRoutes = 1
    }, 300);
  }

  ngOnInit(): void {
    if (this.countInicio == 0) {
      this.ItemsToSend = {
        ...this.ItemsToSend,
        colorText: (this.colorText && this.colorText != "") ? this.colorText : "#fff",
        fontSizeText: (this.fontSizeText && this.fontSizeText >= 0) ? this.fontSizeText : 20,
        boldText: (this.boldText && this.boldText != "") ? this.boldText : "unset",
        text: this.text,
        renderFor: (this.renderFor && this.renderFor != "") ? this.renderFor : "span",
        backgroundColor: (this.backgroundColor && this.backgroundColor != "") ? this.backgroundColor : "#222",
        border: (this.border == 0) ? this.border : 1,
        align: (this.align && this.align != "") ? this.align : "center",
      }
      this.countInicio = 1
    }
  }

  ngDoCheck() {

    if (this.requestData == 1 && this.count1 == 0) {
      console.log(this.ItemsToSend)
      this.emitOnChange.emit(this.ItemsToSend);
      this.count1 = 1
    }

  }

}
