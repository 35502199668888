import { Component, OnInit } from '@angular/core';
import { ModalImageService } from './modal-image.service';
import { ToastrService } from 'ngx-toastr';
import { UsersService } from 'src/app/services/users.service';
import { CipHeaderService } from 'src/app/services/cip-header.service';
import { CipFooterService } from 'src/app/services/cfm-footer.service';

@Component({
  selector: 'app-modal-image',
  templateUrl: './modal-image.component.html',
  styles: [
  ]
})
export class ModalImageComponent {

  n_vez = 0

  theme = {
    name: "light",
    background: "bg-light",
    background2: "bg-light",
    text: "text-dark",
    btnType1: "btn-primary",
  }

  constructor(
    public __UsersService: UsersService,
    public __ModalImageService: ModalImageService,
    public toastr: ToastrService,
    public __CipHeaderService: CipHeaderService,
    public __CipFooterService: CipFooterService,
  ) { }

  cerrarModal() {
    this.n_vez = 0
    this.__ModalImageService.ocultarModal();
  }

  onSelect(event: any) {

    // let elemento = event.target;

    // if (elemento.value.split('.')[1] != "png" && elemento.value.split('.')[1] != "jpg" && elemento.value.split('.')[1] != "jpeg" && elemento.value.split('.')[1] != "JPG") {
    //   // swal.fire("Remember that you can only upload images of type png, jpg and jpeg", "", "error");
    //   this.toastr.error("Solo se permiten los formatos: png, jpg and jpeg", 'Advertencia!', { timeOut: 5000 });

    //   (<HTMLInputElement>document.getElementById("fileUpload_IMAGEN")).value = "";
    //   return;
    // }

    // if (elemento.files.length > 0) {

    //   if ((elemento.files[0].size / 1000) > 450) {
    //     this.toastr.error("Solo se permite imagenes con tamaño menor a 450 Kilobytes", 'Advertencia!', { timeOut: 5000 });

    //     (<HTMLInputElement>document.getElementById("fileUpload_IMAGEN")).value = "";
    //     return;
    //   }

    //   let formData = new FormData();
    //   formData.append('token', this.__UsersService.token);
    //   formData.append('id', "" + this.__ModalImageService.id);

    //   formData.append('file', elemento.files[0]);

    //   if (this.__ModalImageService.toDo == "cip_header") {

    //     this.__CipHeaderService.uploadImageCipHeader(formData)
    //       .subscribe((data: any) => {
    //         console.log("-------------------")
    //         console.log(data)
    //         console.log("-------------------")
    //         if (data.ok) {
    //           this.toastr.success("Se agregó la imagen correctamente", 'Éxito!', { timeOut: 6000 });
    //         } else {
    //           this.toastr.error("OCURRIÓ UN ERROR, POR FAVOR VUELVE A ACTUALIZAR", 'Advertencia!', { timeOut: 6000 });
    //         }
    //         this.__CipHeaderService.__afterUpdateImage();
    //         (<HTMLInputElement>document.getElementById("fileUpload_IMAGEN")).value = "";
    //         this.cerrarModal();
    //       });
    //     return;

    //   } else if (this.__ModalImageService.toDo == "cip_footer") {

    //     this.__CipFooterService.uploadImageCipFooter(formData)
    //       .subscribe((data: any) => {
    //         console.log("-------------------")
    //         console.log(data)
    //         console.log("-------------------")
    //         if (data.ok) {
    //           this.toastr.success("Se agregó la imagen correctamente", 'Éxito!', { timeOut: 6000 });
    //         } else {
    //           this.toastr.error("OCURRIÓ UN ERROR, POR FAVOR VUELVE A ACTUALIZAR", 'Advertencia!', { timeOut: 6000 });
    //         }
    //         this.__CipFooterService.__afterUpdateImage();
    //         (<HTMLInputElement>document.getElementById("fileUpload_IMAGEN")).value = "";
    //         this.cerrarModal();
    //       });
    //     return;

    //   } else {

    //     this.toastr.error("Not found, call to programmer", 'Advertencia!', { timeOut: 6000 });
    //     this.cerrarModal();

    //   }

    // }
  }

}
