import { Component, DoCheck, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { OurPricesService } from 'src/app/services/our-prices.service';
import { UsersService } from 'src/app/services/users.service';
import { ModalDescriptionItemOurPricesService } from './modal-description-item-our-prices.service';

@Component({
  selector: 'app-modal-description-item-our-prices',
  templateUrl: './modal-description-item-our-prices.component.html',
  styles: [
  ]
})
export class ModalDescriptionItemOurPricesComponent implements DoCheck {

  n_vez = 0
  stateGeneral = 1
  btnUpdate = 1

  ARRAY_COMPONENTS = []

  DESCRIPTION_DATA = {
    id: "",
    id_cfm_ourprices_item: 0,
    text: "",
    textAlign: "center",
    token: "",
  }

  ERROR_DESCRIPTION_DATA = {
    text: "",
  }

  theme = {
    name: "light",
    background: "bg-light",
    background2: "bg-light",
    text: "text-dark",
    btnType1: "btn-primary",
  }

  constructor(
    public __UsersService: UsersService,
    public __OurPricesService: OurPricesService,
    private toastr: ToastrService,
    public __ModalDescriptionItemOurPricesService: ModalDescriptionItemOurPricesService,
  ) { }

  ngDoCheck() {

    if (this.__ModalDescriptionItemOurPricesService.oculto == "" && this.__ModalDescriptionItemOurPricesService.id > 0 && this.n_vez == 0) {
      this.getItemDescriptionById().then(
        () => {

        }
      ).catch(error => console.error("Error en la promesa", error));
      this.n_vez = 1;
    }

    if (this.__ModalDescriptionItemOurPricesService.oculto == "" && this.__ModalDescriptionItemOurPricesService.forWhat == "changeOrder" && this.n_vez == 0) {
      this.getItemDescriptionByIdItem().then(
        () => {

        }
      ).catch(error => console.error("Error en la promesa", error));
      this.n_vez = 1;
    }
  }

  getItemDescriptionByIdItem(): Promise<boolean> {
    return new Promise((resolve, reject) => {

      let dataEnviar = {
        "idItem": this.__ModalDescriptionItemOurPricesService.idItemOurPrice,
        "token": this.__UsersService.token
      }

      this.__OurPricesService.getItemDescriptionByIdItem(dataEnviar)
        .subscribe((respuesta: any) => {
          if (respuesta.ok) {
            this.stateGeneral = 1
            this.btnUpdate = 1

            this.ARRAY_COMPONENTS = respuesta.data_array

            resolve(true);
            return;
          }
          resolve(true);
        });
    })
  }

  getItemDescriptionById(): Promise<boolean> {
    return new Promise((resolve, reject) => {

      let dataEnviar = {
        "id": this.__ModalDescriptionItemOurPricesService.id,
        "token": this.__UsersService.token
      }

      this.__OurPricesService.getItemDescriptionById(dataEnviar)
        .subscribe((respuesta: any) => {
          if (respuesta.ok) {
            this.stateGeneral = 1
            this.btnUpdate = 1

            this.DESCRIPTION_DATA = {
              ...this.DESCRIPTION_DATA,
              id: respuesta.data_array[0].id,
              text: respuesta.data_array[0].text,
              textAlign: respuesta.data_array[0].textAlign,
            }

            resolve(true);
            return;
          }
          resolve(true);
        });
    })
  }

  changeOrderPosition(e: any = []) {
    let newData = "";
    e.data.forEach((element: any) => {
      if (newData == "") {
        newData += element.id
      } else {
        newData += "|" + element.id
      }
    });
    let dataEnviar = {
      "page": "HOME_PAGE",
      "token": this.__UsersService.token,
      "data": newData,
      "previousIndex": e.previousIndex,
      "currentIndex": e.currentIndex,
    }

    this.__OurPricesService.changeOrderPositionOfDescriptionItem(dataEnviar)
      .subscribe((resp2: any) => {
        this.__OurPricesService.__refresh()
        if (resp2.ok) {
          this.getItemDescriptionByIdItem()
        } else {
          this.toastr.error(resp2.msn, 'Advertencia!', { timeOut: 4000 });
        }
      });
  }

  update() {
    if (!this.validarCampo()) {
      console.log("NOPE")
      return;
    }

    this.DESCRIPTION_DATA.token = this.__UsersService.token;

    this.__OurPricesService.updateDescriptionItem(this.DESCRIPTION_DATA)
      .subscribe((resp2: any) => {
        if (resp2.ok) {
          this.toastr.success("", 'Actualizado', { timeOut: 4500 });
          this.__OurPricesService.__refresh();
          this.cerrarModal();
        } else {
          this.toastr.error(resp2.msn, 'Ocurrió un error!', { timeOut: 4500 });
        }
      });
  }

  add() {
    if (!this.validarCampo()) {
      console.log("NOPE")
      return;
    }

    this.DESCRIPTION_DATA.id_cfm_ourprices_item = this.__ModalDescriptionItemOurPricesService.idItemOurPrice;
    this.DESCRIPTION_DATA.token = this.__UsersService.token;

    this.__OurPricesService.addDescriptionItem(this.DESCRIPTION_DATA)
      .subscribe((resp2: any) => {
        if (resp2.ok) {
          this.toastr.success("", 'Actualizado', { timeOut: 4500 });
          this.__OurPricesService.__refresh();
          this.cerrarModal();
        } else {
          this.toastr.error(resp2.msn, 'Ocurrió un error!', { timeOut: 4500 });
        }
      });
  }

  validarCampo() {
    this.limpiar();
    if (this.DESCRIPTION_DATA.text == "" || !this.DESCRIPTION_DATA.text) {
      this.ERROR_DESCRIPTION_DATA.text = "Este campo no puede estar vacio";
      return false;
    } else if ((this.DESCRIPTION_DATA.text).length > 100) {
      this.ERROR_DESCRIPTION_DATA.text = "Este campo no puede tener mas de 100 caracteres";
      return false;
    }

    this.limpiar();
    return true;
  }

  limpiar() {
    this.ERROR_DESCRIPTION_DATA = {
      text: "",
    }
  }

  cerrarModal() {
    this.n_vez = 0
    this.stateGeneral = 1
    this.btnUpdate = 1

    this.DESCRIPTION_DATA = {
      id: "",
      id_cfm_ourprices_item: 0,
      text: "",
      textAlign: "center",
      token: "",
    }

    this.__ModalDescriptionItemOurPricesService.ocultarModal()
  }

}
