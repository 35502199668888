import { Injectable, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { URL_SERVICIOS } from 'src/app/config/config';

import { of } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class ChatsService {

  constructor(
    public http: HttpClient
  ) { }

  getAllGroupByEmail(data: any) {
    let url = URL_SERVICIOS + "chat_getAllGroupByEmail&busqueda=" + data.busqueda + "&token=" + data.token;

    return this.http.get(url)
      .map((resp: any) => {
        return resp;
      })
      .catch(err => {
        console.log("+++++++++++++++++++++++++");
        console.log(err);
        console.log("+++++++++++++++++++++++++");
        return of(err);
      });
  }

  getChatByEmailClientInToken(data: any) {
    let url = URL_SERVICIOS + "chat_getChatByEmailClientInToken&token=" + data.token;

    return this.http.get(url)
      .map((resp: any) => {
        return resp;
      })
      .catch(err => {
        console.log("+++++++++++++++++++++++++");
        console.log(err);
        console.log("+++++++++++++++++++++++++");
        return of(err);
      });
  }

  getChatByEmailClient(data: any) {
    let url = URL_SERVICIOS + "chat_getChatByEmailClient&token=" + data.token + "&email=" + data.emailClient;

    return this.http.get(url)
      .map((resp: any) => {
        return resp;
      })
      .catch(err => {
        console.log("+++++++++++++++++++++++++");
        console.log(err);
        console.log("+++++++++++++++++++++++++");
        return of(err);
      });
  }

  add(data: any) {
    let url = URL_SERVICIOS + "chat_add";

    return this.http.post(url, data)
      .map((resp: any) => {
        return resp;
      })
      .catch(err => {
        console.log("+++++++++++++++++++++++++");
        console.log(err);
        console.log("+++++++++++++++++++++++++");

        return of(err);
      });
  }
}
