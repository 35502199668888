<div class="fondo-negro animated fadeIn" [ngClass]="__ModalMenuItemService.oculto">

    <div class="modal" style="display:block;" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-lg" role="document">

            <!-- CREATE -->
            <div *ngIf="__ModalMenuItemService.id===0" class="modal-content {{theme.background2}} {{theme.text}}"
                style="overflow: auto;height: 580px;">
                <div class="modal-header">
                    <h2 class="modal-title">Agregar ítem para el menú {{MENU_ITEM_DATA.idFather}}</h2>

                    <button (click)="cerrarModal()" type="button" class="close text-danger font-large-2"
                        aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body" align="center">
                    <div class="form-group row">
                        <label class="col-md-3 col-form-label">Nombre a mostrar</label>
                        <div class="col-md-9">
                            <input class="form-control" [(ngModel)]="MENU_ITEM_DATA.name" name="name" />
                            <p class="text-danger">{{MENU_ITEM_DATA_ERROR.name}}</p>
                        </div>
                    </div>

                    <div class="form-group row mt-1">
                        <label class="col-md-3 col-form-label">Enlace</label>
                        <div class="col-md-9">
                            <app-search-routes *ngIf="stateToShowSearchRoutes===1" (emitOnChange)="getLink($event)">
                            </app-search-routes>
                            <!-- <input class="form-control" [(ngModel)]="MENU_ITEM_DATA.url" name="url" />
                            <p class="text-danger">{{MENU_ITEM_DATA_ERROR.url}}</p> -->
                        </div>
                    </div>

                    <div class="form-group row mt-1">
                        <label class="col-md-3 col-form-label">Enlace seleccionado</label>
                        <div class="col-md-9">
                            <!-- <input class="form-control" [(ngModel)]="MENU_ITEM_DATA.url" name="url" /> -->
                            <p class="text-warning">{{urlSelected}}</p>
                        </div>
                    </div>

                    <div class="form-group row mt-1">
                        <label class="col-md-3 col-form-label">Abrir en otra pestaña?</label>
                        <div class="col-md-9">
                            <select [(ngModel)]="MENU_ITEM_DATA.openOnAnotherPage" name="openOnAnotherPage"
                                class="form-control {{theme.background}} {{theme.text}}">
                                <option value="0">No</option>
                                <option value="1">Si</option>
                            </select>
                        </div>
                    </div>


                </div>
                <div *ngIf="btnPost===1" class="modal-footer">
                    <button (click)="cerrarModal()" type="button" class="btn btn-danger">cerrar</button>
                    <button (click)="add()" type="button" class="btn btn-primary">Agregar</button>
                </div>
            </div>

            <!-- UPDATE -->
            <div *ngIf="__ModalMenuItemService.id>0" class="modal-content {{theme.background2}} {{theme.text}}"
                style="overflow: auto;height: 580px;">
                <div class="modal-header">
                    <h2 class="modal-title">Actualizar ítem para el menú {{MENU_ITEM_DATA.idFather}}</h2>

                    <button (click)="cerrarModal()" type="button" class="close text-danger font-large-2"
                        aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body" align="center">
                    <div class="form-group row">
                        <label class="col-md-3 col-form-label">Nombre a mostrar</label>
                        <div class="col-md-9">
                            <input class="form-control" [(ngModel)]="MENU_ITEM_DATA.name" name="name" />
                            <p class="text-danger">{{MENU_ITEM_DATA_ERROR.name}}</p>
                        </div>
                    </div>

                    <div class="form-group row mt-1">
                        <label class="col-md-3 col-form-label">Enlace</label>
                        <div class="col-md-9">
                            <app-search-routes *ngIf="stateToShowSearchRoutes===1" (emitOnChange)="getLink($event)">
                            </app-search-routes>
                            <!-- <input class="form-control" [(ngModel)]="MENU_ITEM_DATA.url" name="url" />
                            <p class="text-danger">{{MENU_ITEM_DATA_ERROR.url}}</p> -->
                        </div>
                    </div>

                    <div class="form-group row mt-1">
                        <label class="col-md-3 col-form-label">Enlace seleccionado</label>
                        <div class="col-md-9">
                            <!-- <input class="form-control" [(ngModel)]="MENU_ITEM_DATA.url" name="url" /> -->
                            <p class="text-warning">{{urlSelected}}</p>
                        </div>
                    </div>

                    <div class="form-group row mt-1">
                        <label class="col-md-3 col-form-label">Abrir en otra pestaña?</label>
                        <div class="col-md-9">
                            <select [(ngModel)]="MENU_ITEM_DATA.openOnAnotherPage" name="openOnAnotherPage"
                                class="form-control {{theme.background}} {{theme.text}}">
                                <option value="0">No</option>
                                <option value="1">Si</option>
                            </select>
                        </div>
                    </div>


                </div>
                <div *ngIf="btnPost===1" class="modal-footer">
                    <button (click)="cerrarModal()" type="button" class="btn btn-danger">cerrar</button>
                    <button (click)="update()" type="button" class="btn btn-primary">Actualizar</button>
                </div>
            </div>

        </div>
    </div>
</div>