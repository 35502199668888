import { Component, OnInit } from '@angular/core';
// import '../../assets/css/appForAdmin.css';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  // styleUrls: ['./admin.component.css']
})
export class AdminComponent {

  constructor() { }
}
