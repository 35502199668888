import { Injectable, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { URL_SERVICIOS } from 'src/app/config/config';

import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CipHeaderService {

  constructor(
    public http: HttpClient
  ) { }

  @Output() afterUpdateImage: EventEmitter<boolean> = new EventEmitter();
  __afterUpdateImage() {
    this.afterUpdateImage.emit();
  }

  getByIdCip(data: any) {
    let url = URL_SERVICIOS + "CIPH_getByIdCip&idCip=" + data.idCip + "&token=" + data.token;

    return this.http.get(url)
      .map((resp: any) => {
        return resp;
      })
      .catch(err => {
        console.log("+++++++++++++++++++++++++");
        console.log(err);
        console.log("+++++++++++++++++++++++++");
        return of(err);
      });
  }

  getHeaderDataForStore(data: any) {
    let url = URL_SERVICIOS + "CIPH_getHeaderDataForStore&ipClient=" + data.ipClient;

    return this.http.get(url)
      .map((resp: any) => {
        return resp;
      })
      .catch(err => {
        console.log("+++++++++++++++++++++++++");
        console.log(err);
        console.log("+++++++++++++++++++++++++");
        return of(err);
      });
  }

  uploadBackgroundColor(data: any) {
    let url = URL_SERVICIOS + "CIPH_uploadBackgroundColor";

    return this.http.post(url, data)
      .map((resp: any) => {
        return resp;
      })
      .catch(err => {
        console.log("+++++++++++++++++++++++++");
        console.log(err);
        console.log("+++++++++++++++++++++++++");

        return of(err);
      });
  }

  removeImage(data: any) {
    let url = URL_SERVICIOS + "CIPH_removeImage";

    return this.http.post(url, data)
      .map((resp: any) => {
        return resp;
      })
      .catch(err => {
        console.log("+++++++++++++++++++++++++");
        console.log(err);
        console.log("+++++++++++++++++++++++++");

        return of(err);
      });
  }

  uploadData(data: any) {
    let url = URL_SERVICIOS + "CIPH_uploadData";

    return this.http.post(url, data)
      .map((resp: any) => {
        return resp;
      })
      .catch(err => {
        console.log("+++++++++++++++++++++++++");
        console.log(err);
        console.log("+++++++++++++++++++++++++");

        return of(err);
      });
  }

  updateValueImage(data: any) {
    let url = URL_SERVICIOS + "CIPH_updateValueImage";

    return this.http.post(url, data)
      .map((resp: any) => {
        return resp;
      })
      .catch(err => {
        console.log("+++++++++++++++++++++++++");
        console.log(err);
        console.log("+++++++++++++++++++++++++");

        return of(err);
      });
  }
}
