<div [style.backgroundColor]="CPI_FOOTER.backgroundColor"
    class="footer-container footer-one pt-100 pb-50 pt-3 position-relative">
    <div class="container wide">
        <div class="row">
            <div *ngFor="let item of ITEMS_FOOTER" class="col footer-single-widget">

                <h4 [style.color]="CPI_FOOTER.colorHeader" class="widget-title">{{item['fi_header']}}</h4>
                <img *ngIf="item['i_routeImg']!==''" src="{{BASE_URL_IMG}}{{item['i_routeImg']}}"
                    class="img-fluid w-75 m-auto d-block" alt="">

                <div class="footer-nav-container">
                    <nav>
                        <ul>
                            <li *ngFor="let urls of item['itemsMenu']">
                                <a [style.color]="CPI_FOOTER.colorText">{{urls['name']}}</a>
                            </li>
                        </ul>
                    </nav>
                </div>

                <app-rich-text *ngIf="item['fi_text']!==''" [mydataTextHtml]="item['fi_text']" [mode]="'editor'"
                    [displayWarningWhiteBG]="0">
                </app-rich-text>
            </div>

            <!-- <div class="col footer-single-widget">

                <img *ngIf="CPI_FOOTER.urlImage!==''" src="{{BASE_URL_IMG}}{{CPI_FOOTER.urlImage}}"
                    class="img-fluid w-75 m-auto d-block" alt="">

                <div class="copyright-text">
                    <p [style.color]="CPI_FOOTER.colorText">{{CPI_FOOTER.text1}}</p>
                </div>

            </div> -->
            <!-- <div class="col footer-single-widget footer_centerLess479">
                <h4 [style.color]="CPI_FOOTER.colorHeader" class="widget-title">ABOUT</h4>

                <div class="footer-nav-container">
                    <nav>
                        <ul>
                            <li>
                                <a *ngIf="vista==='COMPLETE'" [style.color]="CPI_FOOTER.colorText"
                                    [routerLink]="['/about-us']">
                                    Acerca de nosotros
                                </a>
                                <a *ngIf="vista==='ONLYSEE'" [style.color]="CPI_FOOTER.colorText">
                                    Acerca de nosotros
                                </a>
                            </li>
                            <li><a [style.color]="CPI_FOOTER.colorText" [routerLink]="['/contact-us']">Contáctanos</a>
                            </li>
                        </ul>
                    </nav>
                </div>

            </div> -->

            <!-- <div class="col footer-single-widget footer_centerLess479">

                <h4 [style.color]="CPI_FOOTER.colorHeader" class="widget-title">USEFUL LINKS</h4>

                <div class="footer-nav-container">
                    <nav>
                        <ul>
                            <li><a [style.color]="CPI_FOOTER.colorText" href="#">Returns</a></li>
                            <li><a [style.color]="CPI_FOOTER.colorText" href="#">Support Policy</a></li>
                            <li><a [style.color]="CPI_FOOTER.colorText" href="#">Size guide</a></li>
                            <li><a [style.color]="CPI_FOOTER.colorText" href="#">FAQs</a></li>
                        </ul>
                    </nav>
                </div>

            </div> -->

            <!-- <div class="col footer-single-widget footer_centerLess479">

                <h4 [style.color]="CPI_FOOTER.colorHeader" class="widget-title">FOLLOW US ON</h4>

                <div class="footer-nav-container footer-social-links">
                    <nav>
                        <ul>
                            <li>
                                <a [style.color]="CPI_FOOTER.colorText" href="//twitter.com">
                                    <i [style.color]="CPI_FOOTER.colorText" class="fa fa-twitter"></i> Twitter
                                </a>
                            </li>
                            <li>
                                <a [style.color]="CPI_FOOTER.colorText" href="//facebook.com">
                                    <i [style.color]="CPI_FOOTER.colorText" class="fa fa-facebook"></i> Facebook
                                </a>
                            </li>
                            <li>
                                <a [style.color]="CPI_FOOTER.colorText" href="//instagram.com">
                                    <i [style.color]="CPI_FOOTER.colorText" class="fa fa-instagram"></i> Instagram
                                </a>
                            </li>
                            <li>
                                <a [style.color]="CPI_FOOTER.colorText" href="//youtube.com">
                                    <i [style.color]="CPI_FOOTER.colorText" class="fa fa-youtube"></i> Youtube
                                </a>
                            </li>
                        </ul>
                    </nav>
                </div>

            </div> -->


            <!-- <div class="col footer-single-widget footer_centerLess479">

                <div class="footer-subscription-widget">
                    <h4 [style.color]="CPI_FOOTER.colorHeader" class="footer-subscription-title">Subscribe.</h4>
                    <p [style.color]="CPI_FOOTER.colorText" class="subscription-subtitle">Subscribe to our newsletter to
                        receive news on update.</p>

                    <div class="subscription-form">
                        <form id="mc-form" class="mc-form" novalidate="true">
                            <input [style.color]="CPI_FOOTER.colorText" type="email" placeholder="Your email address"
                                required="" name="EMAIL">
                            <button type="submit"><i class="ion-ios-arrow-thin-right"></i></button>
                        </form>
                    </div>

                    <div class="mailchimp-alerts">
                        <div class="mailchimp-submitting"></div>
                        <div class="mailchimp-success"></div>
                        <div class="mailchimp-error"></div>
                    </div>

                </div>

            </div> -->
        </div>
    </div>
</div>