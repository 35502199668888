import { Injectable, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { URL_SERVICIOS } from 'src/app/config/config';

import { of } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class OurPricesService {

  constructor(
    public http: HttpClient
  ) { }

  @Output() refresh: EventEmitter<boolean> = new EventEmitter();
  __refresh() {
    this.refresh.emit();
  }

  // ---------------------------------------MAIN OUR PRICE
  addOrUpdate(data: any) {
    let url = URL_SERVICIOS + "ourPrices_addOrUpdate";

    return this.http.post(url, data)
      .map((resp: any) => {
        return resp;
      })
      .catch(err => {
        console.log("+++++++++++++++++++++++++");
        console.log(err);
        console.log("+++++++++++++++++++++++++");

        return of(err);
      });
  }

  getByIdCFM(data: any) {
    let url = URL_SERVICIOS + "ourPrices_getByIdCFM&idCFM=" + data.idCFM + "&token=" + data.token;

    return this.http.get(url)
      .map((resp: any) => {
        return resp;
      })
      .catch(err => {
        console.log("+++++++++++++++++++++++++");
        console.log(err);
        console.log("+++++++++++++++++++++++++");
        return of(err);
      });
  }

  updateValueImage(data: any) {
    let url = URL_SERVICIOS + "ourPrices_updateValueImage";

    return this.http.post(url, data)
      .map((resp: any) => {
        return resp;
      })
      .catch(err => {
        console.log("+++++++++++++++++++++++++");
        console.log(err);
        console.log("+++++++++++++++++++++++++");

        return of(err);
      });
  }

  removeImage(data: any) {
    let url = URL_SERVICIOS + "ourPrices_removeImgById";

    return this.http.post(url, data)
      .map((resp: any) => {
        return resp;
      })
      .catch(err => {
        console.log("+++++++++++++++++++++++++");
        console.log(err);
        console.log("+++++++++++++++++++++++++");

        return of(err);
      });
  }

  deleteComplete(data: any) {
    let url = URL_SERVICIOS + "ourPrices_deleteComplete";

    return this.http.post(url, data)
      .map((resp: any) => {
        return resp;
      })
      .catch(err => {
        console.log("+++++++++++++++++++++++++");
        console.log(err);
        console.log("+++++++++++++++++++++++++");

        return of(err);
      });
  }

  // ---------------------------------------ITEM OUR PRICE

  changeOrderPositionOfItem(data: any) {
    let url = URL_SERVICIOS + "changeOrderPositionOfItem";

    return this.http.post(url, data)
      .map((resp: any) => {
        return resp;
      })
      .catch(err => {
        console.log("----------------");
        console.log(err);
        console.log("----------------");

        return of(err);
      });
  }

  deleteItemComplete(data: any) {
    let url = URL_SERVICIOS + "ourPrices_deleteItemComplete";

    return this.http.post(url, data)
      .map((resp: any) => {
        return resp;
      })
      .catch(err => {
        console.log("+++++++++++++++++++++++++");
        console.log(err);
        console.log("+++++++++++++++++++++++++");

        return of(err);
      });
  }

  updateItem(data: any) {
    let url = URL_SERVICIOS + "ourPrices_updateItem";

    return this.http.post(url, data)
      .map((resp: any) => {
        return resp;
      })
      .catch(err => {
        console.log("+++++++++++++++++++++++++");
        console.log(err);
        console.log("+++++++++++++++++++++++++");

        return of(err);
      });
  }

  getTotalByIdCFM_forStore(data: any) {
    let url = URL_SERVICIOS + "ourPrices_getTotalByIdCFM_forStore&idCFM=" + data.idCFM;

    return this.http.get(url)
      .map((resp: any) => {
        return resp;
      })
      .catch(err => {
        console.log("+++++++++++++++++++++++++");
        console.log(err);
        console.log("+++++++++++++++++++++++++");
        return of(err);
      });
  }

  getItemsByIdCFM(data: any) {
    let url = URL_SERVICIOS + "ourPrices_getItemsByIdCFM&idCFM=" + data.idCFM + "&token=" + data.token;

    return this.http.get(url)
      .map((resp: any) => {
        return resp;
      })
      .catch(err => {
        console.log("+++++++++++++++++++++++++");
        console.log(err);
        console.log("+++++++++++++++++++++++++");
        return of(err);
      });
  }

  getItemById(data: any) {
    let url = URL_SERVICIOS + "ourPrices_getItemById&id=" + data.id + "&token=" + data.token;

    return this.http.get(url)
      .map((resp: any) => {
        return resp;
      })
      .catch(err => {
        console.log("+++++++++++++++++++++++++");
        console.log(err);
        console.log("+++++++++++++++++++++++++");
        return of(err);
      });
  }

  addItem(data: any) {
    let url = URL_SERVICIOS + "ourPrices_addItem";

    return this.http.post(url, data)
      .map((resp: any) => {
        return resp;
      })
      .catch(err => {
        console.log("+++++++++++++++++++++++++");
        console.log(err);
        console.log("+++++++++++++++++++++++++");

        return of(err);
      });
  }

  // ---------------------------------------DESCRIPTION OUR PRICE

  changeOrderPositionOfDescriptionItem(data: any) {
    let url = URL_SERVICIOS + "changeOrderPositionOfDescriptionItem";

    return this.http.post(url, data)
      .map((resp: any) => {
        return resp;
      })
      .catch(err => {
        console.log("----------------");
        console.log(err);
        console.log("----------------");

        return of(err);
      });
  }

  deleteDescriptionItem(data: any) {
    let url = URL_SERVICIOS + "ourPrices_deleteDescriptionItem";

    return this.http.post(url, data)
      .map((resp: any) => {
        return resp;
      })
      .catch(err => {
        console.log("+++++++++++++++++++++++++");
        console.log(err);
        console.log("+++++++++++++++++++++++++");

        return of(err);
      });
  }

  getItemDescriptionById(data: any) {
    let url = URL_SERVICIOS + "ourPrices_getItemDescriptionById&id=" + data.id + "&token=" + data.token;

    return this.http.get(url)
      .map((resp: any) => {
        return resp;
      })
      .catch(err => {
        console.log("+++++++++++++++++++++++++");
        console.log(err);
        console.log("+++++++++++++++++++++++++");
        return of(err);
      });
  }

  getItemDescriptionByIdItem(data: any) {
    let url = URL_SERVICIOS + "ourPrices_getItemDescriptionByIdItem&idItem=" + data.idItem + "&token=" + data.token;

    return this.http.get(url)
      .map((resp: any) => {
        return resp;
      })
      .catch(err => {
        console.log("+++++++++++++++++++++++++");
        console.log(err);
        console.log("+++++++++++++++++++++++++");
        return of(err);
      });
  }

  addDescriptionItem(data: any) {
    let url = URL_SERVICIOS + "ourPrices_addDescriptionItem";

    return this.http.post(url, data)
      .map((resp: any) => {
        return resp;
      })
      .catch(err => {
        console.log("+++++++++++++++++++++++++");
        console.log(err);
        console.log("+++++++++++++++++++++++++");

        return of(err);
      });
  }

  updateDescriptionItem(data: any) {
    let url = URL_SERVICIOS + "ourPrices_updateDescriptionItem";

    return this.http.post(url, data)
      .map((resp: any) => {
        return resp;
      })
      .catch(err => {
        console.log("+++++++++++++++++++++++++");
        console.log(err);
        console.log("+++++++++++++++++++++++++");

        return of(err);
      });
  }
}
