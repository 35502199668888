import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ModalMenuItemService {

  public oculto = "oculto";
  public id = 0;
  public idMenu = 0;
  public idFather = 0;

  constructor() { }

  ocultarModal() {
    this.oculto = "oculto";
    this.id = 0;
    this.idMenu = 0;
    this.idFather = 0;
  }
  mostrarModal(idMenu: number, id: number = 0, idFather: number = 0) {
    this.oculto = "";
    this.id = id;
    this.idMenu = idMenu;
    this.idFather = idFather;
  }
}
