import { Component, OnInit } from '@angular/core';
import { ModalPagesService } from 'src/app/modals/modal-page/modal-pages.service';
import { PagesService } from 'src/app/services/pages.service';
import { RedirectToService } from 'src/app/services/redirect-to.service';

@Component({
  selector: 'app-side-bar-admin',
  templateUrl: './side-bar-admin.component.html',
  styleUrls: ['./side-bar-admin.component.css']
})
export class SideBarAdminComponent implements OnInit {

  activeSidebar1 = 0

  ROL: any
  email: any

  PAGES = []

  theme = {
    name: "light",
    background: "bg-light",
    text: "text-dark",
    btnType1: "btn-primary",
  }

  constructor(
    public _PagesService: PagesService,
    public __RedirectToService: RedirectToService,
    public __ModalPagesService: ModalPagesService,
  ) { }
  // 
  ngOnInit(): void {
    this._PagesService.refresh.subscribe(isOpen => {
      this.getAllPages();
    });

    this.getAllPages()
  }

  addPage() {
    this.__ModalPagesService.mostrarModal();
  }

  getAllPages(): Promise<boolean> {
    return new Promise((resolve, reject) => {

      let dataEnviar = {
        "state": 2
      }

      this._PagesService.getAll(dataEnviar)
        .subscribe((respuesta: any) => {
          if (respuesta.ok) {
            this.PAGES = respuesta.data_array
            resolve(true);
            return;
          }
          resolve(true);
        });
    })
  }

  manageComponentFromPage(idPage: number) {
    let dataToSend = {
      idPage: idPage,
    }

    this.__RedirectToService.toManagePage(dataToSend)
  }

  toggleActiveSidebar() {
    if (this.activeSidebar1 == 1) {
      document.getElementsByClassName("wrapper")[0].classList.remove("nav-collapsed");
      document.getElementsByClassName("wrapper")[0].classList.remove("menu-collapsed");
      this.activeSidebar1 = 0
      return
    }
    if (this.activeSidebar1 == 0) {
      document.getElementsByClassName("wrapper")[0].classList.add("nav-collapsed");
      document.getElementsByClassName("wrapper")[0].classList.add("menu-collapsed");
      this.activeSidebar1 = 1
      return
    }
  }

}
