import { Component, DoCheck, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { PagesService } from 'src/app/services/pages.service';
import { UsersService } from 'src/app/services/users.service';
import { ModalPagesService } from './modal-pages.service';

@Component({
  selector: 'app-modal-page',
  templateUrl: './modal-page.component.html',
  styles: [
  ]
})
export class ModalPageComponent implements DoCheck {

  n_vez = 0

  PAGE_DATA =
    {
      "id": 0,
      "token": "",
      "name": "",
      "route": "",
      "state": 0,
    };

  PAGE_DATA_ERROR =
    {
      "name": "",
      "route": "",
    };

  theme = {
    name: "light",
    background: "bg-light",
    background2: "bg-light",
    text: "text-dark",
    btnType1: "btn-primary",
  }

  constructor(
    public __ModalPagesService: ModalPagesService,
    public __usuarioService: UsersService,
    public _PagesService: PagesService,
    // public __ProviderService: ProviderService,
    private toastr: ToastrService,
  ) {
    // this.theme = this._SettingsService.loadThemeFromStorage()
  }

  ngDoCheck() {
    if (this.__ModalPagesService.oculto == "" && this.__ModalPagesService.id > 0 && this.n_vez == 0) {
      this.PAGE_DATA.id = this.__ModalPagesService.id;
      this.n_vez = 1;
      this.getByFilter();
    }
  }

  cerrarModal() {
    this.n_vez = 0

    this.PAGE_DATA =
    {
      "id": 0,
      "token": "",
      "name": "",
      "route": "",
      "state": 0,
    };

    this.PAGE_DATA_ERROR =
    {
      "name": "",
      "route": "",
    };

    this.__ModalPagesService.ocultarModal()
  }

  getByFilter(): Promise<boolean> {
    return new Promise((resolve, reject) => {

      let dataEnviar = {
        "whereKey": "id",
        "whereValue": this.PAGE_DATA.id,
        "typeSearch": "exact",
      }

      this._PagesService.getByFilter(dataEnviar)
        .subscribe((respuesta: any) => {
          console.log(respuesta)
          if (respuesta.ok) {
            this.PAGE_DATA.name = respuesta.data_array[0].PAG_name;
            this.PAGE_DATA.route = respuesta.data_array[0].PAG_route;
            resolve(true);
            return;
          }
          resolve(true);
        });
    })
  }

  updatePage() {

    if (!this.validarCampo()) {
      console.log("NOPE")
      return;
    }

    this.PAGE_DATA.token = this.__usuarioService.token;

    this._PagesService.addOrUpdate(this.PAGE_DATA)
      .subscribe((resp3: any) => {
        console.log("-------resp3--------------")
        console.log(resp3)
        if (resp3.ok) {
          this.toastr.success("", 'Página agregada', { timeOut: 5000 });
          this._PagesService.__refresh();
          this.cerrarModal();
        } else {
          this.toastr.error(resp3.msn, 'Ocurrió un error!', { timeOut: 5000 });
        }
      });

  }

  addPage() {

    if (!this.validarCampo()) {
      console.log("NOPE")
      return;
    }

    this.PAGE_DATA.token = this.__usuarioService.token;

    console.log(this.PAGE_DATA)

    this._PagesService.addOrUpdate(this.PAGE_DATA)
      .subscribe((resp3: any) => {
        console.log("-------resp3--------------")
        console.log(resp3)
        if (resp3.ok) {
          this.toastr.success("", 'Página agregada', { timeOut: 5000 });
          this._PagesService.__refresh();
          this.cerrarModal();
        } else {
          this.toastr.error(resp3.msn, 'Ocurrió un error!', { timeOut: 5000 });
        }
      });
    return;

  }

  validarCampo() {

    if (this.PAGE_DATA.name == "") {
      this.PAGE_DATA_ERROR.name = "Este campo no puede estar vacío";
      return false;
    }
    this.limpiar()
    if ((this.PAGE_DATA.name).length > 150) {
      this.PAGE_DATA_ERROR.name = "Este campo no puede tener mas de 150 caracteres";
      return false;
    }

    if (this.PAGE_DATA.route == "") {
      this.PAGE_DATA_ERROR.route = "Este campo no puede estar vacío";
      return false;
    }
    this.limpiar()
    if ((this.PAGE_DATA.route).length > 100) {
      this.PAGE_DATA_ERROR.route = "Este campo no puede tener mas de 100 caracteres";
      return false;
    }
    this.limpiar()
    return true;
  }

  limpiar() {
    this.PAGE_DATA_ERROR =
    {
      "name": "",
      "route": "",
    };
  }

}
