import { Component, DoCheck, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { SlideshowService } from 'src/app/services/slideshow.service';
import { UsersService } from 'src/app/services/users.service';
import { ModalItemSlideshowService } from './modal-item-slideshow.service';
import { URL_IMG } from 'src/app/config/config';
@Component({
  selector: 'app-modal-item-slideshow',
  templateUrl: './modal-item-slideshow.component.html',
})
export class ModalItemSlideshowComponent implements DoCheck {

  n_vez = 0

  stateChooseImg = 0
  btnUpdate = 1
  collapseTitle = ""
  collapseDescription = ""

  boxMyRichText = {
    display: 0,
    requestData: 0
  }

  // ARRAY_COMPONENTS = []

  ITEM_SLIDESHOW_NOW = {
    idComponentInPage: 0,
    idImg: 0,
    urlImg: "",

    titleText: "",
    titleColor: "",
    titleFontSize: 0,
    titleBoldText: "unset",

    descriptionText: "",
    descriptionColor: "",
    descriptionFontSize: 0,
    descriptionBoldText: "unset",

    backgroundColorTextBox: "",
  }

  ITEM_SLIDESHOW_NOW_ERROR = {
    idComponentInPage: "",
    idImg: "",
    urlImg: "",
    backgroundColorTextBox: "",
  }

  theme = {
    name: "light",
    background: "bg-light",
    background2: "bg-light",
    text: "text-dark",
    btnType1: "btn-primary",
  }

  constructor(
    public __UsersService: UsersService,
    private toastr: ToastrService,
    public __ModalItemSlideshowService: ModalItemSlideshowService,
    public __SlideshowService: SlideshowService,
  ) { }

  ngDoCheck() {

    if (this.__ModalItemSlideshowService.oculto == "" && this.__ModalItemSlideshowService.id > 0 && this.n_vez == 0) {
      this.getItemById().then(
        () => {

        }
      ).catch(error => console.error("Error en la promesa", error));
      this.n_vez = 1;

    }
  }

  setImg(e: any, state: number) {
    console.log(e)
    if (state === 1) {
      this.ITEM_SLIDESHOW_NOW.idImg = e.idImg
      this.ITEM_SLIDESHOW_NOW.urlImg = URL_IMG + e.urlImg
      return;
    }
    this.stateChooseImg = 0
    this.ITEM_SLIDESHOW_NOW.idImg = 0
    this.ITEM_SLIDESHOW_NOW.urlImg = ''
  }

  toggleCollapseTitle() {
    if (this.collapseTitle == "") {
      this.collapseTitle = "show"
    } else {
      this.collapseTitle = ""
    }
  }

  toggleCollapseDescription() {
    if (this.collapseDescription == "") {
      this.collapseDescription = "show"
    } else {
      this.collapseDescription = ""
    }
  }

  eventUpdate() {
    this.btnUpdate = 0
    this.boxMyRichText.requestData = 1;

    setTimeout(() => {
      this.updateItem()
    }, 2000);
    return;
  }

  setTextFromMyRichText(e: any, to: string) {
    setTimeout(() => {
      if (to == "title") {
        this.boxMyRichText.display = 0

        this.ITEM_SLIDESHOW_NOW.titleText = e.text
        this.ITEM_SLIDESHOW_NOW.titleFontSize = e.fontSizeText
        this.ITEM_SLIDESHOW_NOW.titleColor = e.colorText
        this.ITEM_SLIDESHOW_NOW.titleBoldText = e.boldText
        return
      }

      if (to == "description") {
        this.boxMyRichText.display = 0

        this.ITEM_SLIDESHOW_NOW.descriptionText = e.text
        this.ITEM_SLIDESHOW_NOW.descriptionFontSize = e.fontSizeText
        this.ITEM_SLIDESHOW_NOW.descriptionColor = e.colorText
        this.ITEM_SLIDESHOW_NOW.descriptionBoldText = e.boldText
        return
      }

    }, 500);
  }

  updateItem() {

    if (!this.validarCampo()) {
      console.log("NOPE")
      return;
    }

    let dataEnviar = {
      "id": this.__ModalItemSlideshowService.id,
      "backgroundColorTextBox": this.ITEM_SLIDESHOW_NOW.backgroundColorTextBox,

      "titleText": this.ITEM_SLIDESHOW_NOW.titleText,
      "titleFontSize": this.ITEM_SLIDESHOW_NOW.titleFontSize,
      "titleColor": this.ITEM_SLIDESHOW_NOW.titleColor,
      "titleBoldText": this.ITEM_SLIDESHOW_NOW.titleBoldText,

      "descriptionText": this.ITEM_SLIDESHOW_NOW.descriptionText,
      "descriptionColor": this.ITEM_SLIDESHOW_NOW.descriptionColor,
      "descriptionFontSize": this.ITEM_SLIDESHOW_NOW.descriptionFontSize,
      "descriptionBoldText": this.ITEM_SLIDESHOW_NOW.descriptionBoldText,

      "idImg": this.ITEM_SLIDESHOW_NOW.idImg,

      "token": this.__UsersService.token
    }

    this.__SlideshowService.updateItemData(dataEnviar)
      .subscribe((respuesta: any) => {
        this.btnUpdate = 1
        if (respuesta.ok) {
          this.toastr.success("Ítem actualizado", 'Éxito!', { timeOut: 7000 });
          this.cerrarModal()
          return
        }
        this.toastr.error("ocurrió un error", 'Advertencia!', { timeOut: 7000 });
      });
  }

  cerrarModal() {
    this.__SlideshowService.__refreshData()
    this.ITEM_SLIDESHOW_NOW = {
      idComponentInPage: 0,
      idImg: 0,
      urlImg: "",

      titleText: "",
      titleColor: "",
      titleFontSize: 0,
      titleBoldText: "unset",

      descriptionText: "",
      descriptionColor: "",
      descriptionFontSize: 0,
      descriptionBoldText: "unset",

      backgroundColorTextBox: "",
    }

    this.ITEM_SLIDESHOW_NOW_ERROR = {
      idComponentInPage: "",
      idImg: "",
      urlImg: "",
      backgroundColorTextBox: "",
    }

    this.boxMyRichText = {
      display: 0,
      requestData: 0
    }

    this.collapseTitle = ""
    this.collapseDescription = ""

    this.n_vez = 0;
    this.__ModalItemSlideshowService.ocultarModal()
  }

  getItemById(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.boxMyRichText.display = 0
      let dataEnviar = {
        "idCip": this.__ModalItemSlideshowService.id,
        "token": this.__UsersService.token
      }

      this.__SlideshowService.getItemById(dataEnviar)
        .subscribe((respuesta: any) => {
          this.boxMyRichText.display = 1
          if (respuesta.ok) {
            this.ITEM_SLIDESHOW_NOW = {
              idComponentInPage: this.__ModalItemSlideshowService.idComponentInPage,
              idImg: respuesta.data_array.idImg,
              urlImg: URL_IMG + respuesta.data_array.routeImg,

              titleText: (respuesta.data_array.titleText) ? respuesta.data_array.titleText : "",
              titleColor: (respuesta.data_array.titleColor) ? respuesta.data_array.titleColor : "",
              titleFontSize: (respuesta.data_array.titleFontSize) ? respuesta.data_array.titleFontSize : "",
              titleBoldText: respuesta.data_array.titleBoldText,

              descriptionText: (respuesta.data_array.descriptionText) ? respuesta.data_array.descriptionText : "",
              descriptionColor: (respuesta.data_array.descriptionColor) ? respuesta.data_array.descriptionColor : "",
              descriptionFontSize: (respuesta.data_array.descriptionFontSize) ? respuesta.data_array.descriptionFontSize : "",
              descriptionBoldText: respuesta.data_array.descriptionBoldText,

              backgroundColorTextBox: respuesta.data_array.backgroundColorTextBox,
            }
            resolve(true);
            return;
          }
          resolve(true);
        });
    })
  }

  removeImgItemById() {
    let dataEnviar = {
      "idImage": this.ITEM_SLIDESHOW_NOW.idImg,
      "idItem": this.__ModalItemSlideshowService.id,
      "token": this.__UsersService.token
    }

    this.__SlideshowService.removeImgItemById(dataEnviar)
      .subscribe((respuesta: any) => {
        if (respuesta.ok) {
          this.toastr.success("Imagen eliminada", 'Éxito!', { timeOut: 7000 });
          this.getItemById()
          return
        }
        this.toastr.error("Error", 'Advertencia!', { timeOut: 7000 });
      });
  }

  limpiar() {
    this.ITEM_SLIDESHOW_NOW_ERROR = {
      idComponentInPage: "",
      idImg: "",
      urlImg: "",
      backgroundColorTextBox: "",
    }
  }

  validarCampo() {
    this.limpiar();

    if ((this.ITEM_SLIDESHOW_NOW.backgroundColorTextBox).length > 25) {
      this.ITEM_SLIDESHOW_NOW_ERROR.backgroundColorTextBox = "Este campo no puede tener mas de 25 caracteres";
      return false;
    }

    // if ((this.ITEM_SLIDESHOW_NOW.colorTitle).length > 25) {
    //   this.ITEM_SLIDESHOW_NOW_ERROR.colorTitle = "Este campo no puede tener mas de 25 caracteres";
    //   return false;
    // }

    // if ((this.ITEM_SLIDESHOW_NOW.title).length > 100) {
    //   this.ITEM_SLIDESHOW_NOW_ERROR.title = "Este campo no puede tener mas de 100 caracteres";
    //   return false;
    // }

    // if ((this.ITEM_SLIDESHOW_NOW.descriptionColor).length > 25) {
    //   this.ITEM_SLIDESHOW_NOW_ERROR.descriptionColor = "Este campo no puede tener mas de 25 caracteres";
    //   return false;
    // }

    // if ((this.ITEM_SLIDESHOW_NOW.descriptionText).length > 500) {
    //   this.ITEM_SLIDESHOW_NOW_ERROR.descriptionText = "Este campo no puede tener mas de 500 caracteres";
    //   return false;
    // }

    this.limpiar();
    return true;
  }

}
