<div [style.display]="(stateToShowChatBox===1)?'block':'none'" class="chat_boxGeneral1">
    <div (click)='stateToShowChatBox=0' #containerMinus class="text-right mb-2"></div>
    <div class="w-100">
        <h4 *ngIf="view==='FORM_IDENTIFY'" class="font-weight-bold font-small-5 text-white">Hola :)</h4>
        <p *ngIf="view==='FORM_IDENTIFY'" class="text-white">
            Por favor ínicia sesión o ingresa tu e-mail para chatear con nosotros
        </p>


        <div *ngIf="view==='FORM_IDENTIFY'" class="card-body">
            <div class="form-group row">
                <label class="col-md-12 col-form-label text-left font-weight-bold text-white">
                    Como quieres ingresar?
                </label>
                <div class="col-md-12">
                    <button [routerLink]="['/myAccount']" type="button" class="btn btn-primary w-100">Con mi
                        usuario</button>
                </div>
                <div class="col-md-12">
                    <button (click)="view='FORM_SET_EMAIL'" type="button" class="btn btn-primary w-100">Invitado con
                        email</button>
                </div>
            </div>
        </div>

        <div *ngIf="view==='FORM_SET_EMAIL'" class="card-body">
            <div class="form-group row">
                <label class="col-md-3 col-form-label text-left font-weight-bold text-white">E-mail</label>
                <div class="col-md-9">
                    <input class="form-control" [(ngModel)]="emailClient" name="emailClient" />
                </div>
            </div>
            <div class="form-group row mt-2">
                <button (click)="sendTokenForGuest()" type="button" class="btn btn-success w-100">Continuar</button>
            </div>
            <div class="form-group row mt-2">
                <button (click)="view='CHECK_TOKEN_GUEST'" type="button" class="btn btn-primary w-100">Ya tengo un
                    token</button>
            </div>
        </div>

        <div *ngIf="view==='CHECK_TOKEN_GUEST'" class="card-body">
            <div class="form-group row">
                <label class="col-md-12 col-form-label text-left font-weight-bold text-white">Ingresa el toque que hemos
                    enviado a tu correo.</label>
                <div class="col-md-12">
                    <input class="form-control" [(ngModel)]="tokenForGuest" name="token" placeholder="token"
                        autocomplete="off" />
                </div>
            </div>
            <div class="form-group row mt-2">
                <button (click)="checkTokenAndSetEmailAsGuest()" type="button"
                    class="btn btn-success w-100">Continuar</button>
                <button (click)="view='FORM_SET_EMAIL'" type="button" class="btn btn-secondary w-100">Volver</button>
            </div>
        </div>

        <ng-container *ngIf="view==='CHAT'">
            <div class="col-12 text-white">
                <p class="d-inline-block font-weight-bold mr-2">E-mail: </p>
                <p class="d-inline-block">{{emailClient}}</p>
            </div>
            <div class="col-12 border-light border"
                style="min-height: 370px;background-color: #000B56;margin: 0 auto !important;background-image: url('../../../assets/img/myImages/bg.png');position: relative;">

                <!-- ------------------------------------------------------------------------ -->

                <div id="cajaChat1" class="col-12 p-0"
                    style="padding-right: 22px !important;overflow: overlay;max-height: 270px;">
                    <ng-container *ngFor="let messa of messages">
                        <div *ngIf="messa['fromWho']==='ADMIN'" class="col-12 p-0" style="clear: both;">
                            <div class="row" style="margin: 0px 12px;width: 94%;">
                                <div class="col-4 p-0 overflow-hidden">
                                    <img src="assets/img/portrait/avatars/avatar-08.png"
                                        class="rounded-circle img-border gradient-summer" height="55" width="55"
                                        alt="Card image">
                                    <span class="text align-center font-small-3 font-weight-bolder"
                                        style="color: #fff;">Mesa de ayuda</span>
                                </div>
                                <div class="mt-1"
                                    style="background: #FFB98E;color: #000237;border-radius: 15px;padding: 15px;max-width: 50%;">
                                    <p [innerHTML]="messa['message']"></p>

                                    <!-- <div *ngIf="messa['files']['length']>0" class="row">
                                            <div class="col-12">
                                                <span class="font-weight-bolder">Archivos adjuntos</span>
                                            </div>
                                            <div *ngFor="let file of messa['files']" class="col-12">
                                                <a href="{{urlForFiles}}{{file.route}}" target="_blank"
                                                    class="text-warning font-weight-bolder">{{file.nameToShow}}</a>
                                            </div>
                                        </div> -->
                                </div>
                            </div>
                        </div>

                        <div *ngIf="messa['fromWho']==='CLIENT'" class="mt-1 float-right p-2 text-white"
                            style="background: #000237;border:1px solid #FFB98E;border-radius: 15px;max-width: 70%;clear: both;">
                            <p style="overflow: auto;color: #FFB98E;">
                                {{messa['message']}}
                            </p>

                            <!-- <div *ngIf="messa.filesDirect.length>0" class="row">
                                    <div class="col-12">
                                        <span class="font-weight-bolder">Archivos adjuntos</span>
                                    </div>
                                    <div *ngFor="let file of messa.filesDirect" class="col-12">
                                        <a href="{{urlForFiles}}{{file.route}}" target="_blank"
                                            class="text-warning font-weight-bolder">{{file.nameToShow}}</a>
                                    </div>
                                </div> -->
                        </div>
                    </ng-container>
                </div>


                <div class="col-12 p-0" style="bottom: 0px;position: absolute;">
                    <div class="row" style="margin: 0px;">
                        <textarea [(ngModel)]="myMessageForSend" class="col-10" name="myMessageForSend" id="" cols="42"
                            rows="3" style="border-radius: 15px;" placeholder="Escribe tu mensaje..."></textarea>
                        <div *ngIf="myMessageForSend!==''" class="col-2 p-0">
                            <i (click)="addFile()" class="fa fa-file-archive-o fa-2x cursor-pointer bg-white"
                                aria-hidden="true"
                                style="margin-top: 5%;color: #111;padding: 10px;border-radius: 50%;"></i>

                            <i (click)="sendMessage()" class="fa fa-paper-plane fa-2x cursor-pointer bg-white"
                                aria-hidden="true"
                                style="margin-top: 5%;color: #111;padding: 10px;border-radius: 50%;"></i>
                        </div>
                    </div>
                </div>

            </div>
        </ng-container>

    </div>
</div>
<div (click)="toggleDisplayChat()" class="cursor-pointer" #dataContainer style="
position: fixed;right: 15px;bottom: 20px;width: 60px;height: 60px;
background-color: #0084ff;display: flex;justify-content: center;
align-items: center;border-radius: 50%;">
</div>