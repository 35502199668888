import { Component, OnInit } from '@angular/core';
import { ViewChild, ElementRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { URL_BASE } from 'src/app/config/config';
import { ChatsService } from 'src/app/services/chats.service';
import { RedirectToService } from 'src/app/services/redirect-to.service';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.css',
  ]
})
export class ChatComponent implements OnInit {
  urlForFiles = URL_BASE;
  @ViewChild('dataContainer') dataContainer!: ElementRef;
  @ViewChild('containerMinus') containerMinus!: ElementRef;

  svgIcon = `<svg width="36" height="36" viewBox="0 0 36 36"><path fill="white" d="M1 17.99C1 8.51488 8.42339 1.5 18 1.5C27.5766 1.5 35 8.51488 35 17.99C35 27.4651 27.5766 34.48 18 34.48C16.2799 34.48 14.6296 34.2528 13.079 33.8264C12.7776 33.7435 12.4571 33.767 12.171 33.8933L8.79679 35.3828C7.91415 35.7724 6.91779 35.1446 6.88821 34.1803L6.79564 31.156C6.78425 30.7836 6.61663 30.4352 6.33893 30.1868C3.03116 27.2287 1 22.9461 1 17.99ZM12.7854 14.8897L7.79161 22.8124C7.31238 23.5727 8.24695 24.4295 8.96291 23.8862L14.327 19.8152C14.6899 19.5398 15.1913 19.5384 15.5557 19.8116L19.5276 22.7905C20.7193 23.6845 22.4204 23.3706 23.2148 22.1103L28.2085 14.1875C28.6877 13.4272 27.7531 12.5704 27.0371 13.1137L21.673 17.1847C21.3102 17.4601 20.8088 17.4616 20.4444 17.1882L16.4726 14.2094C15.2807 13.3155 13.5797 13.6293 12.7854 14.8897Z"></path></svg>`;
  svgMinus = `<svg style="background: #fff;border-radius: 50%;" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16Z" fill="black" fill-opacity="0.04"></path><path d="M22.0278 15.0278C22.5647 15.0278 23 15.4631 23 16.0001C23 16.537 22.5647 16.9723 22.0278 16.9723L9.97222 16.9723C9.43528 16.9723 9 16.537 9 16.0001C9 15.4631 9.43528 15.0278 9.97222 15.0278L22.0278 15.0278Z" fill="black"></path></svg>`

  view = "FORM_IDENTIFY" // FORM_IDENTIFY | FORM_SET_EMAIL | CHAT

  stateToShowChatBox = 0;

  emailClient = ""

  tokenForGuest = ""

  myMessageForSend = ""

  isLoggued = 0

  messages = [];

  constructor(
    private toastr: ToastrService,
    public __UsersService: UsersService,
    public __ChatsService: ChatsService,
    public _redirectTo: RedirectToService,
  ) { }

  ngOnInit(): void {
    this.checkIfIsLoggued()
    setTimeout(() => {
      this.dataContainer.nativeElement.innerHTML = this.svgIcon;
      this.containerMinus.nativeElement.innerHTML = this.svgMinus;
    }, 300);

    this.__UsersService.refreshStateLoginUser.subscribe((resp: any) => {
      this.emailClient = ""
      this.tokenForGuest = ""
      this.myMessageForSend = ""
      if (resp == 1) {
        this.checkIfIsLoggued()
        return
      }
      this.isLoggued = 0
      this.messages = [];
      this.view = "FORM_IDENTIFY"
    })

  }

  getMessageByEmailClient(): Promise<boolean> {
    return new Promise((resolve, reject) => {

      let dataEnviar = {
        "token": (this.tokenForGuest != "") ? this.tokenForGuest : this.__UsersService.token,
      }
      this.messages = [];
      this.__ChatsService.getChatByEmailClientInToken(dataEnviar)
        .subscribe((respuesta: any) => {
          console.log(respuesta)
          if (respuesta.ok && respuesta.tokenData != "") {
            this.messages = respuesta.data_array
            setTimeout(() => this.updateScroll(), 200)
            resolve(true);
            return;
          }
          this.messages = []
          reject(false);
        });
    })
  }

  sendTokenForGuest() {
    if (this.emailClient == "") {
      this.toastr.error("", 'Ingresa tu e-mail', { timeOut: 4000 });
      return;
    }
    if (!this.validateEmail(this.emailClient)) {
      this.toastr.error("", 'Ingresa un e-mail válido', { timeOut: 4000 });
      return;
    }

    let dataToSend = {
      email: this.emailClient
    }

    this.view = '';

    this._redirectTo.generarTokenData(dataToSend).subscribe((resp) => {
      let dataToSend2 = {
        title: "Código de validación",
        description: "<b>Utiliza el siguiente código para continuar: </b>" + resp.tokenData,
        email: "aaronrose853@gmail.com",
      }
      this.__UsersService.sendMail(dataToSend2).subscribe((resp2) => {
        this.toastr.success("", 'Hemos enviado un token a tu correo, por favor revisar.', { timeOut: 7000 });
        this.view = 'CHECK_TOKEN_GUEST'
      })
    })
  }

  checkIfIsLoggued(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (this.__UsersService.token == "") {
        this.isLoggued = 0
        return;
      }

      this._redirectTo.getDataFromToken(this.__UsersService.token)
        .subscribe((respuesta: any) => {
          console.log(respuesta)
          if (respuesta.ok) {
            const { tokenData: { data3: { data: { email } } } } = respuesta;

            this.isLoggued = (email && email != "") ? 1 : 0;
            this.getMessageByEmailClient()
            this.emailClient = email
            this.view = "CHAT"
            resolve(true);
            return
          }

          this.emailClient = ""
          this.tokenForGuest = ""
          this.myMessageForSend = ""
          this.isLoggued = 0
          this.messages = [];

        });
    })
  }

  checkTokenAndSetEmailAsGuest() {
    if (this.tokenForGuest == "") {
      this.tokenForGuest = ""
      this.toastr.error("", 'Ingresa el token que hemos enviado a tu correo.', { timeOut: 4000 });
      return;
    }

    this._redirectTo.getDataFromToken(this.tokenForGuest)
      .subscribe((respuesta: any) => {
        console.log(respuesta)
        if (respuesta.ok) {
          const { tokenData: { data3: { data: { email } } } } = respuesta;
          this.getMessageByEmailClient()

          this.view = "CHAT"
          this.emailClient = email
        } else {
          this.toastr.error("", 'El token ingresado no es válido o ha vencido, por favor obtener un token nuevo.', { timeOut: 4000 });
        }

      });

    // console.log(this.validateEmail(this.emailClient));
    // this.getMessageByEmailClient()

    // this.view = "CHAT"
  }

  addFile() {

  }

  validateEmail(email: string) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      return (true)
    }
    return (false)
  }

  sendMessage() {

    let json = {
      emailClient: this.emailClient,
      message: this.myMessageForSend,
      fromWho: "CLIENT",
      token: this.__UsersService.token
    }
    this.myMessageForSend = ""
    this.__ChatsService.add(json)
      .subscribe((resp2: any) => {
        console.log(resp2)

        this.getMessageByEmailClient()
        if (resp2.ok) {

        } else {
          this.toastr.error(resp2.msn, 'Advertencia!', { timeOut: 4000 });
        }
      });
  }

  updateScroll() {
    let cajaChat1 = <HTMLVideoElement>document.getElementById("cajaChat1")
    cajaChat1.scrollTop = cajaChat1.scrollHeight
  }

  toggleDisplayChat() {
    console.log(this.stateToShowChatBox)
    if (this.stateToShowChatBox == 1) {
      this.stateToShowChatBox = 0
    } else {
      this.stateToShowChatBox = 1
      if (this.view == "CHAT") {
        setTimeout(() => this.updateScroll(), 200)
      }
    }
  }

}
