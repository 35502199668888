import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { UsersService } from '../users.service';

@Injectable({
  providedIn: 'root'
})
export class CheckIfIsLogoutGuard implements CanActivate {
  constructor(
    public _UsersService: UsersService,
    public router: Router
  ) {

  }

  canActivate() {

    return this._UsersService.estaLogueado().map((data: any) => {
      if (data == false) {
        console.log("No está logueado");
        this.router.navigate(['/login']);
        return false;
      } else {
        return true;
      }
    });

  }
}
