export const json_icons = [
    {
        name: "fa-area-chart",
        value: "fa fa-area-chart",
    },
    {
        name: "fa-bar-chart",
        value: "fa fa-bar-chart",
    },
    {
        name: "fa-bolt",
        value: "fa fa-bolt",
    },
    {
        name: "fa-laptop",
        value: "fa fa-laptop",
    },
    {
        name: "fa-line-chart",
        value: "fa fa-line-chart",
    },
    {
        name: "fa-cc-visa",
        value: "fa fa-cc-visa",
    },
    {
        name: "fa-cc-paypal",
        value: "fa fa-cc-paypal",
    },
    {
        name: "fa-btc",
        value: "fa fa-btc",
    },
    {
        name: "fa-american-sign-language-interpreting",
        value: "fa fa-american-sign-language-interpreting",
    },
    {
        name: "fa-usd",
        value: "fa fa-usd",
    },
    {
        name: "fa-money",
        value: "fa fa-money",
    },
    {
        name: "fa-youtube",
        value: "fa fa-youtube",
    },
    {
        name: "fa-hand-peace-o",
        value: "fa fa-hand-peace-o",
    },
    {
        name: "fa-heart",
        value: "fa fa-heart",
    },
    {
        name: "fa-gavel",
        value: "fa fa-gavel",
    },
    {
        name: "fa-phone",
        value: "fa fa-phone",
    },
    {
        name: "fa-sign-language",
        value: "fa fa-sign-language",
    }
]