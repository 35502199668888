import { Injectable, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { URL_SERVICIOS } from 'src/app/config/config';

import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  constructor(
    public http: HttpClient
  ) { }

  @Output() refresh: EventEmitter<boolean> = new EventEmitter();
  __refresh() {
    this.refresh.emit();
  }

  searchProduct(data: any) {
    let url = URL_SERVICIOS + "product_searchProduct&wordToFind=" + data.wordToFind + "&offset=" + data.offset + "&limit=" + data.limit + "&token=" + data.token;

    return this.http.get(url)
      .map((resp: any) => {
        return resp;
      })
      .catch(err => {
        console.log("+++++++++++++++++++++++++");
        console.log(err);
        console.log("+++++++++++++++++++++++++");
        return of(err);
      });
  }

  searchVariantProduct(data: any) {
    let url = URL_SERVICIOS + "product_searchVariantProduct&wordToFind=" + data.wordToFind + "&offset=" + data.offset + "&limit=" + data.limit + "&token=" + data.token;

    return this.http.get(url)
      .map((resp: any) => {
        return resp;
      })
      .catch(err => {
        console.log("+++++++++++++++++++++++++");
        console.log(err);
        console.log("+++++++++++++++++++++++++");
        return of(err);
      });
  }

  searchProductForStore(data: any) {
    let url = URL_SERVICIOS + "product_searchProductForStore&wordToFind=" + data.wordToFind + "&offset=" + data.offset + "&limit=" + data.limit;

    return this.http.get(url)
      .map((resp: any) => {
        return resp;
      })
      .catch(err => {
        console.log("+++++++++++++++++++++++++");
        console.log(err);
        console.log("+++++++++++++++++++++++++");
        return of(err);
      });
  }

  getProductById(data: any) {
    let url = URL_SERVICIOS + "product_getProductById&productId=" + data.productId + "&token=" + data.token;

    return this.http.get(url)
      .map((resp: any) => {
        return resp;
      })
      .catch(err => {
        console.log("+++++++++++++++++++++++++");
        console.log(err);
        console.log("+++++++++++++++++++++++++");
        return of(err);
      });
  }

  getProductByIdForStore(data: any) {
    let url = URL_SERVICIOS + "product_getProductByIdForStore&productId=" + data.productId;

    return this.http.get(url)
      .map((resp: any) => {
        return resp;
      })
      .catch(err => {
        console.log("+++++++++++++++++++++++++");
        console.log(err);
        console.log("+++++++++++++++++++++++++");
        return of(err);
      });
  }

  getImgsByIdProduct(data: any) {
    let url = URL_SERVICIOS + "product_getImgsByIdProduct&productId=" + data.productId + "&token=" + data.token;

    return this.http.get(url)
      .map((resp: any) => {
        return resp;
      })
      .catch(err => {
        console.log("+++++++++++++++++++++++++");
        console.log(err);
        console.log("+++++++++++++++++++++++++");
        return of(err);
      });
  }

  getVariantsByIdProduct(data: any) {
    let url = URL_SERVICIOS + "product_getVariantsByIdProduct&productId=" + data.productId + "&token=" + data.token;

    return this.http.get(url)
      .map((resp: any) => {
        return resp;
      })
      .catch(err => {
        console.log("+++++++++++++++++++++++++");
        console.log(err);
        console.log("+++++++++++++++++++++++++");
        return of(err);
      });
  }

  getVariantsById(data: any) {
    let url = URL_SERVICIOS + "product_getVariantsById&idVariant=" + data.idVariant + "&token=" + data.token;

    return this.http.get(url)
      .map((resp: any) => {
        return resp;
      })
      .catch(err => {
        console.log("+++++++++++++++++++++++++");
        console.log(err);
        console.log("+++++++++++++++++++++++++");
        return of(err);
      });
  }

  addProduct(data: any) {
    let url = URL_SERVICIOS + "product_addProduct";

    return this.http.post(url, data)
      .map((resp: any) => {
        return resp;
      })
      .catch(err => {
        console.log("----------------");
        console.log(err);
        console.log("----------------");

        return of(err);
      });
  }

  removeProduct(data: any) {
    let url = URL_SERVICIOS + "product_removeProduct";

    return this.http.post(url, data)
      .map((resp: any) => {
        return resp;
      })
      .catch(err => {
        console.log("----------------");
        console.log(err);
        console.log("----------------");

        return of(err);
      });
  }

  updateProduct(data: any) {
    let url = URL_SERVICIOS + "product_updateProduct";

    return this.http.post(url, data)
      .map((resp: any) => {
        return resp;
      })
      .catch(err => {
        console.log("----------------");
        console.log(err);
        console.log("----------------");

        return of(err);
      });
  }

  uploadImage(data: any) {
    let url = URL_SERVICIOS + "product_uploadImage";

    return this.http.post(url, data)
      .map((resp: any) => {
        return resp;
      })
      .catch(err => {
        console.log("+++++++++++++++++++++++++");
        console.log(err);
        console.log("+++++++++++++++++++++++++");

        return of(err);
      });
  }

  variant_uploadImage(data: any) {
    let url = URL_SERVICIOS + "product_variant_uploadImage";

    return this.http.post(url, data)
      .map((resp: any) => {
        return resp;
      })
      .catch(err => {
        console.log("+++++++++++++++++++++++++");
        console.log(err);
        console.log("+++++++++++++++++++++++++");

        return of(err);
      });
  }

  removeProductImgById(data: any) {
    let url = URL_SERVICIOS + "product_removeProductImgById";

    return this.http.post(url, data)
      .map((resp: any) => {
        return resp;
      })
      .catch(err => {
        console.log("+++++++++++++++++++++++++");
        console.log(err);
        console.log("+++++++++++++++++++++++++");

        return of(err);
      });
  }

  // ----------------------------------------------------------------------------



  removeVariantImgById(data: any) {
    let url = URL_SERVICIOS + "product_removeVariantImgById";

    return this.http.post(url, data)
      .map((resp: any) => {
        return resp;
      })
      .catch(err => {
        console.log("+++++++++++++++++++++++++");
        console.log(err);
        console.log("+++++++++++++++++++++++++");

        return of(err);
      });
  }

  deleteVariantById(data: any) {
    let url = URL_SERVICIOS + "product_deleteVariantById";

    return this.http.post(url, data)
      .map((resp: any) => {
        return resp;
      })
      .catch(err => {
        console.log("----------------");
        console.log(err);
        console.log("----------------");

        return of(err);
      });
  }

  addVariant(data: any) {
    let url = URL_SERVICIOS + "product_addVariant";

    return this.http.post(url, data)
      .map((resp: any) => {
        return resp;
      })
      .catch(err => {
        console.log("----------------");
        console.log(err);
        console.log("----------------");

        return of(err);
      });
  }

  updateVariant(data: any) {
    let url = URL_SERVICIOS + "product_updateVariant";

    return this.http.post(url, data)
      .map((resp: any) => {
        return resp;
      })
      .catch(err => {
        console.log("----------------");
        console.log(err);
        console.log("----------------");

        return of(err);
      });
  }

  // ---------------------------------

  searchTag(data: any) {
    let url = URL_SERVICIOS + "product_searchTag&idProduct=" + data.idProduct + "&wordToFind=" + data.wordToFind + "&token=" + data.token;

    return this.http.get(url)
      .map((resp: any) => {
        return resp;
      })
      .catch(err => {
        console.log("+++++++++++++++++++++++++");
        console.log(err);
        console.log("+++++++++++++++++++++++++");
        return of(err);
      });
  }

  addTagToProduct(data: any) {
    let url = URL_SERVICIOS + "product_addTagToProduct";

    return this.http.post(url, data)
      .map((resp: any) => {
        return resp;
      })
      .catch(err => {
        console.log("----------------");
        console.log(err);
        console.log("----------------");

        return of(err);
      });
  }

  deleteTagById(data: any) {
    let url = URL_SERVICIOS + "product_deleteTagById";

    return this.http.post(url, data)
      .map((resp: any) => {
        return resp;
      })
      .catch(err => {
        console.log("----------------");
        console.log(err);
        console.log("----------------");

        return of(err);
      });
  }

}
