import { Injectable, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { URL_SERVICIOS } from 'src/app/config/config';

import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ImagesService {

  constructor(
    public http: HttpClient
  ) { }

  @Output() refresh: EventEmitter<boolean> = new EventEmitter();
  __refresh() {
    this.refresh.emit();
  }

  uploadImage(data: any) {
    let url = URL_SERVICIOS + "images_uploadImage";

    return this.http.post(url, data)
      .map((resp: any) => {
        return resp;
      })
      .catch(err => {
        console.log("+++++++++++++++++++++++++");
        console.log(err);
        console.log("+++++++++++++++++++++++++");

        return of(err);
      });
  }

  removeImgById(data: any) {
    let url = URL_SERVICIOS + "images_removeImgById";

    return this.http.post(url, data)
      .map((resp: any) => {
        return resp;
      })
      .catch(err => {
        console.log("+++++++++++++++++++++++++");
        console.log(err);
        console.log("+++++++++++++++++++++++++");

        return of(err);
      });
  }

  getByAll(data: any) {
    let url = URL_SERVICIOS + "images_getByAll&token=" + data.token;

    return this.http.get(url)
      .map((resp: any) => {
        return resp;
      })
      .catch(err => {
        console.log("+++++++++++++++++++++++++");
        console.log(err);
        console.log("+++++++++++++++++++++++++");
        return of(err);
      });
  }
}
