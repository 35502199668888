import { Component, DoCheck, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { URL_IMG } from 'src/app/config/config';
import { CartService } from 'src/app/services/cart.service';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-cart-multifunction',
  templateUrl: './cart-multifunction.component.html',
  styles: [
  ]
})
export class CartMultifunctionComponent implements OnInit, DoCheck {
  @Output() emitOnChange_menuStateToShow = new EventEmitter<any>();

  @Input() mode = "";
  @Input() activeMenuState = "";

  count1 = 0
  quantityToAdd = 0

  baseUrlImg = URL_IMG

  CART_ITEMS_TO_ADD = []

  DATA = {
    "backgroundColor": "",
    "colorText": "",
    "routeImage": "",
  }

  // activeMenuState = ""

  constructor(
    public _CartService: CartService,
    public __UsersService: UsersService,
  ) { }

  ngOnInit(): void {
    this._CartService.refresh.subscribe((resp) => {
      this.count1 = 0
    });
  }

  ngDoCheck() {

    if (this.mode == "icon" && this.count1 == 0) {
      let currentCartStorage = JSON.parse(localStorage.getItem("cart") + "");
      let quantityToAdd = 0;
      // console.log(currentCartStorage)
      if (currentCartStorage) {
        for (let i = 0; i < currentCartStorage.length; i++) {
          quantityToAdd += currentCartStorage[i].quantityToAdd;
        }
      }
      this.quantityToAdd = quantityToAdd;
      this.count1 = 1
    }

    if (this.mode == "floatCart" && this.count1 == 0) {
      document.addEventListener("click", this.checkOutToClose)

      let currentCartStorage = JSON.parse(localStorage.getItem("cart") + "");

      let dataToCheck = {
        data: currentCartStorage,
      }
      // let quantityToAdd = 0;
      // console.log(currentCartStorage)
      if (currentCartStorage) {
        this._CartService.addAndCheckDataToShowOnStore(dataToCheck)
          .subscribe((resp2: any) => {
            this.CART_ITEMS_TO_ADD = resp2["data"];
            // if (resp2.ok) {
            //   this.toastr.success("Variante agregada con éxito.", 'Éxito!', { timeOut: 4000 });
            //   this.__ProductService.__refresh()
            //   this.cerrarModal()
            // } else {
            //   this.toastr.error(resp2.msn, 'Advertencia!', { timeOut: 4000 });
            // }
          });
      }

      this.count1 = 1
    }

  }

  checkOutToClose = (e: any): void => {
    e.preventDefault();
    if (e.target.id == "cart-overlay") {
      this._CartService.__refresh();
    }
  };

  toggleActiveMenu() {
    if (this.activeMenuState == "active-cart-overlay") {
      this.emitOnChange_menuStateToShow.emit("");
      return
    }
    if (this.activeMenuState == "") {
      this.emitOnChange_menuStateToShow.emit("active-cart-overlay");
      return
    }
  }

}
