<div class="fondo-negro animated fadeIn" [ngClass]="__ModalEntryService.oculto">

    <div class="modal" style="display:block;" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content {{theme.background2}} {{theme.text}}">
                <div class="modal-header">

                    <h2 *ngIf="__ModalEntryService.id===0" class="modal-title">Nuevo ingreso</h2>

                    <button (click)="cerrarModal()" type="button" class="close text-danger font-large-2"
                        aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body" align="center">

                    <div *ngIf="__ModalEntryService.id===0" class="card-body">

                        <div class="form-group row">
                            <label class="col-md-3 label-control" for="striped-form-4">Producto</label>
                            <div class="col-md-9">
                                <input [(ngModel)]="ENTRY_DATA.idProduct" readonly type="text"
                                    class="form-control d-none {{theme.background}} {{theme.text}}" name="idProduct">

                                <input [(ngModel)]="ENTRY_DATA.nameProduct"
                                    (keyup)="searchVariantProduct(ENTRY_DATA.nameProduct,1)" autocomplete="none"
                                    type="text" (focus)="searchVariantProduct(ENTRY_DATA.nameProduct,1)"
                                    class="form-control {{theme.background}} {{theme.text}}" name="nameProduct"
                                    placeholder="Buscar producto">
                                <p class="text-danger">{{ENTRY_DATA_ERROR.idProduct}}</p>
                                <div class="box-shadow-2 overflow-auto"
                                    style="background: #fff;top: -14px;position: relative;max-height: 150px;">

                                    <ul *ngIf="stateTableToShow===1" class="pt-2 pb-2 m-0 text-left">
                                        <li *ngFor="let i of PRODUCT_DATA"
                                            class="p-1 border cursor-pointer selectedHover"
                                            style="list-style-type:none;" (click)="setProduct(i)">
                                            <div class="row">
                                                <div class="col-9">
                                                    <div class="row">
                                                        <div class="col-7">
                                                            <span>
                                                                <p class="font-weight-bold m-0 d-inline">Producto: </p>
                                                                {{i['P_name']}}
                                                            </span>
                                                        </div>
                                                        <div class="col-5">
                                                            <span>
                                                                <p class="font-weight-bold m-0 d-inline">Variante: </p>
                                                                {{i['V_name']}}
                                                            </span>
                                                        </div>
                                                        <div class="col-7">
                                                            <span>
                                                                <p class="font-weight-bold m-0 d-inline">Precio: </p>
                                                                {{i['V_price']}}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-3">
                                                    <img class="w-100" src="{{urlGeneralImg}}{{i['I_route']}}">
                                                </div>
                                            </div>
                                        </li>
                                    </ul>

                                </div>
                            </div>
                        </div>

                        <div class="form-group row mb-1">
                            <label class="col-md-3 label-control" for="striped-form-4">Variante</label>
                            <div class="col-md-9">
                                <input readonly [(ngModel)]="ENTRY_DATA.variantMeasure" type="text"
                                    class="form-control {{theme.background}} {{theme.text}}" name="variantMeasure"
                                    placeholder="variantMeasure">
                                <p class="text-danger">{{ENTRY_DATA_ERROR.idVariant}}</p>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-md-3 label-control" for="striped-form-4">Cantidad</label>
                            <div class="col-md-9">
                                <input [(ngModel)]="ENTRY_DATA.quantity" type="number"
                                    class="form-control {{theme.background}} {{theme.text}}" name="quantity"
                                    placeholder="quantity">
                                <p class="text-danger">{{ENTRY_DATA_ERROR.quantity}}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button *ngIf="__ModalEntryService.id===0" (click)="addEntry()" type="button" id="btn_add_entry"
                        class="btn btn-primary">Agregar</button>
                </div>
            </div>
        </div>
    </div>
</div>