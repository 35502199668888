<ng-container *ngIf="mode==='icon'">
    <div class="single-icon cart">
        <a (click)="toggleActiveMenu()" id="offcanvas-cart-icon">
            <i [style.color]="DATA.colorText" class="ion-ios-cart"></i>
            <span class="count" style="padding: 2px;font-size: 11px;padding-top: 0;">{{quantityToAdd}}</span>
        </a>
    </div>
</ng-container>

<ng-container *ngIf="mode==='floatCart'">
    <div class="cart-overlay {{activeMenuState}}" id="cart-overlay">
        <div class="cart-overlay-close inactive"></div>
        <div class="cart-overlay-content">

            <span class="close-icon" id="cart-close-icon">
                <a (click)="toggleActiveMenu()">
                    <i class="ion-android-close"></i>
                </a>
            </span>


            <div *ngIf="activeMenuState!==''" class="offcanvas-cart-content-container">
                <h3 class="cart-title">Carrito</h3>

                <div class="cart-product-wrapper">
                    <div class="cart-product-container  ps-scroll">

                        <div *ngFor="let itm of CART_ITEMS_TO_ADD" class="single-cart-product">
                            <span class="cart-close-icon">
                                <a href="#"><i class="ti-close"></i></a>
                            </span>
                            <div class="image">
                                <a href="shop-product-basic.html">
                                    <img src="{{baseUrlImg}}{{itm['imgUrl']}}" class="img-fluid" alt="">
                                </a>
                            </div>
                            <div class="content">
                                <h5><a href="shop-product-basic.html">Dark Brown Leather Watch</a></h5>
                                <!-- $1880.00 -->
                                <p><span class="cart-count">{{itm['quantityToAdd']}} x </span>
                                    <span class="discounted-price">{{itm['price']}}
                                    </span>
                                </p>

                            </div>
                        </div>

                    </div>


                    <p class="cart-subtotal">
                        <span class="subtotal-title">Subtotal:</span>
                        <span class="subtotal-amount">$200.00</span>
                    </p>


                    <div class="cart-buttons">
                        <a [routerLink]="['/cart']">Ver carrito</a>
                        <a href="shop-checkout.html">checkout</a>
                    </div>

                    <p class="free-shipping-text">
                        Free Shipping on All Orders Over $100!
                    </p>
                </div>
            </div>
        </div>
    </div>
</ng-container>