import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ModalDescriptionItemOurPricesService {

  public oculto = "oculto";
  public idItemOurPrice = 0;
  public id = 0;
  public forWhat = "";

  constructor() { }

  ocultarModal() {
    this.oculto = "oculto";
    this.idItemOurPrice = 0;
    this.id = 0;
    this.forWhat = "";
  }
  mostrarModal(idItemOurPrice: number, id = 0, forWhat = "addOrUpdate") {
    this.oculto = "";
    this.idItemOurPrice = idItemOurPrice;
    this.id = id;
    this.forWhat = forWhat;
  }
}
