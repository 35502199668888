import { Injectable, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { URL_SERVICIOS } from 'src/app/config/config';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ImageWithTextService {

  constructor(
    public http: HttpClient
  ) { }

  @Output() refreshData: EventEmitter<boolean> = new EventEmitter();
  __refreshData() {
    this.refreshData.emit();
  }

  getImageWithText(data: any) {
    let url = URL_SERVICIOS + "ImageWithText_getByIdCIP&idComponentInPage=" + data.idComponentInPage;

    return this.http.get(url)
      .map((resp: any) => {
        return resp;
      })
      .catch(err => {
        console.log("+++++++++++++++++++++++++");
        console.log(err);
        console.log("+++++++++++++++++++++++++");
        return of(err);
      });
  }

  addOrUpdate(data: any) {
    let url = URL_SERVICIOS + "ImageWithText_addOrUpdate";

    return this.http.post(url, data)
      .map((resp: any) => {
        return resp;
      })
      .catch(err => {
        console.log("+++++++++++++++++++++++++");
        console.log(err);
        console.log("+++++++++++++++++++++++++");

        return of(err);
      });
  }

  updateValueImage(data: any) {
    let url = URL_SERVICIOS + "ImageWithText_updateValueImage";

    return this.http.post(url, data)
      .map((resp: any) => {
        return resp;
      })
      .catch(err => {
        console.log("+++++++++++++++++++++++++");
        console.log(err);
        console.log("+++++++++++++++++++++++++");

        return of(err);
      });
  }

  removeImgById(data: any) {
    let url = URL_SERVICIOS + "ImageWithText_removeImgById";

    return this.http.post(url, data)
      .map((resp: any) => {
        return resp;
      })
      .catch(err => {
        console.log("+++++++++++++++++++++++++");
        console.log(err);
        console.log("+++++++++++++++++++++++++");

        return of(err);
      });
  }

  deleteComponentComplete(data: any) {
    let url = URL_SERVICIOS + "ImageWithText_deleteComponentComplete";

    return this.http.post(url, data)
      .map((resp: any) => {
        return resp;
      })
      .catch(err => {
        console.log("+++++++++++++++++++++++++");
        console.log(err);
        console.log("+++++++++++++++++++++++++");

        return of(err);
      });
  }

}
